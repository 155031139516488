import Logout from '@mui/icons-material/Logout'
import { CircularProgress, Dropdown } from '@mui/joy'
import { useStore } from 'effector-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { viewerModel } from '~/entities/viewer'
import { UserIcon } from '~/shared/ui/Icons'
import { MenuButton, Menu, MenuItem } from './styled'

export function AccountMenu() {
  const isLogoutPending = useStore(viewerModel.logoutFx.pending)

  return (
    <Dropdown>
      <MenuButton
        loading={isLogoutPending}
        disabled={isLogoutPending}
        loadingIndicator={<CircularProgress />}
      >
        <UserIcon />
      </MenuButton>
      <Menu>
        <MenuItem component={Link} to='/user-personal-data'>
          Персональные данные
        </MenuItem>
        <MenuItem onClick={() => viewerModel.signOut()}>
          <Logout />
          Выйти
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}
