import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'name',
    label: 'ФИО',
    type: 'text',
  },
  {
    field: 'email',
    label: 'Email',
    type: 'text',
  },
]
