import { Box } from '@mui/joy'
import { ReactNode, useRef } from 'react'
import {
  FormProvider as ReactHookFormProvider,
  UseFormReturn,
} from 'react-hook-form'
import { FieldErrors, FieldValues } from 'react-hook-form/dist/types'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { formButtonsModel } from '../FormButtons'

type FormProviderProps<TFieldValues extends FieldValues = FieldValues> = {
  children: ReactNode
  form: UseFormReturn<TFieldValues>
  onSuccess?: (values: TFieldValues) => void
  canEdit?: boolean
  errorMessage?: string
}

export function FormProvider<TFieldValues extends FieldValues = FieldValues>({
  children,
  form,
  onSuccess,
  canEdit,
  errorMessage,
  ...props
}: FormProviderProps<TFieldValues>) {
  const { handleSubmit } = form
  const ref = useRef<HTMLElement>()

  const handleDoubleClick = () => {
    if (canEdit) formButtonsModel.editingStarted()
  }

  const handleError = (formFields: FieldErrors<TFieldValues>) => {
    const fields = Object.entries(formFields)

    if (!fields.length) return

    const [fieldName] = fields[0]
    const element = ref.current?.querySelector(`input[name="${fieldName}"]`)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    snackbarEnqueued({
      message:
        errorMessage ||
        'Пожалуйста, заполните все обязательные поля для сохранения.',
      variant: 'warning',
    })
  }

  return (
    <Box ref={ref} onDoubleClick={handleDoubleClick} {...props}>
      <ReactHookFormProvider {...form}>
        <form
          style={{ margin: 0 }}
          {...(onSuccess
            ? { onSubmit: handleSubmit(onSuccess, handleError) }
            : {})}
        >
          {children}
        </form>
      </ReactHookFormProvider>
    </Box>
  )
}
