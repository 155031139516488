import {
  IconButton as JoyIconButton,
  Tooltip,
  TooltipProps,
  IconButtonProps as JoyIconButtonProps,
} from '@mui/joy'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/lib/theme'

export type IconButtonProps = {
  onClick?: () => void
  to?: string
  href?: string
  variant?: 'brand' | 'gray' | 'red' | 'yellow' | 'black'
  tooltipProps?: Omit<TooltipProps, 'children'>
  show?: boolean
} & Omit<JoyIconButtonProps, 'variant'>

export function IconButton({
  children,
  sx,
  to,
  href,
  variant,
  tooltipProps,
  show = true,
  ...props
}: IconButtonProps) {
  const link = useMemo(() => (to ? { component: Link, to } : {}), [to])
  const linkHref = useMemo(() => (href ? { component: 'a', href } : {}), [href])

  const customVariant = useMemo(() => {
    if (variant === 'gray') {
      return {
        background: myPalette.gray['100'],

        '&:hover': {
          background: myPalette.gray['100'],
          boxShadow: `inset 0px 0px 0px 3px ${myPalette.purple['100']}`,
        },
      }
    }
    if (variant === 'yellow') {
      return {
        background: myPalette.yellow['600'],
        '&:hover': {
          background: myPalette.yellow['700'],
        },
      }
    }
    if (variant === 'brand') {
      return {
        background: myPalette.brand['600'],
        '&:hover': {
          background: myPalette.brand['800'],
        },
      }
    }
    if (variant === 'black') {
      return {
        background: myPalette.gray['900'],
        '&:hover': {
          background: myPalette.gray['800'],
        },
      }
    }
  }, [variant])

  if (!show) return null

  return (
    <Tooltip
      variant='outlined'
      {...tooltipProps}
      title={tooltipProps?.title || null}
    >
      <JoyIconButton
        size='lg'
        {...props}
        {...link}
        {...linkHref}
        sx={{
          ...sx,
          ...customVariant,
          borderRadius: '30px',
          transition: '0.3s ease',
        }}
      >
        {children}
      </JoyIconButton>
    </Tooltip>
  )
}
