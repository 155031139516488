import { AxiosError } from 'axios'
import { AxiosErrorType } from '~/shared/api'

export const mapMessageErrors = (e: AxiosErrorType) => {
  const errorMessage = e.response?.data?.errors
    ?.map((error) => error?.detail)
    .join(', ')
  return errorMessage || 'Ошибка сервера!'
}

export const mapErrorBlobToErrorType = async (e: AxiosError<Blob>) => {
  const errorBlob = e?.response?.data as Blob

  return {
    response: {
      data: {
        errors: JSON.parse(await errorBlob.text())?.errors,
      },
    },
  }
}
