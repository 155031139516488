import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { Company, CompanyAttributes, CompanyFetchOption } from '~/shared/api'
import { isString } from '~/shared/lib/utils'

const domain = createDomain('features.unloadPenalties')

type Inn = CompanyAttributes['inn']

export const Gate = createGate<{ inn: Inn }>()

export const $inn = domain
  .createStore<Inn | null>(null)
  .on(Gate.state, (_, { inn }) => inn)
  .on(Gate.close, () => null)

export const fetchCompanyFx = domain.createEffect<Inn, CompanyFetchOption>({
  async handler(inn) {
    const res = await Company.where('inn', inn).first()
    return res.getData()?.getOptionWithInn() as CompanyFetchOption
  },
})

export const $companyOption = domain
  .createStore<CompanyFetchOption | null>(null)
  .on(fetchCompanyFx.doneData, (_, companyOption) => companyOption)
  .on(Gate.close, () => null)

sample({
  clock: $inn,
  filter: isString,
  target: fetchCompanyFx,
})
