import { Stack, styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Wrapper = styled(Stack)`
  position: fixed;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  height: 56px;
  align-items: center;
  border-radius: 32px;
  padding: 6px 8px;
  background-color: ${myPalette.gray['900']};
  color: ${myPalette.white.main};

  a {
    transition: 0.3s ease;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 32px;
    font-weight: 600;
    white-space: nowrap;
  }

  .active {
    background: ${myPalette.white.main};
    color: ${myPalette.gray['900']};
  }
`
