import { Company } from '~/shared/api'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    label: 'Дата протокола',
    type: 'dateRange',
    startField: 'protocolDateGte',
    endField: 'protocolDateLte',
  },
  {
    field: 'protocolNumber',
    label: 'Номер протокола',
    type: 'text',
  },
  {
    field: 'carPlateNumber',
    label: 'Автомобиль (гос.номер)',
    type: 'text',
  },
  {
    field: 'carVin',
    label: 'Автомобиль (VIN)',
    type: 'text',
  },
  {
    field: 'tmsSubdivisionName',
    label: 'Подразделение авто',
    type: 'text',
  },
  {
    field: 'carSts',
    label: 'СТС',
    type: 'text',
  },
  {
    field: 'paymentOrgInn',
    label: 'Организация',
    type: 'autocomplete',
    fetchOptions: Company.fetchOptionsWithInn,
  },
  {
    field: 'dayUntilDiscountEnd',
    label: 'Дней до окончания скидки',
    type: 'text',
  },
  {
    field: 'dayUntilPayEnd',
    label: 'Дней до передачи ФССП',
    type: 'text',
  },
  {
    label: 'Дата ПП',
    type: 'dateRange',
    startField: 'bankclientLastExportDateGte',
    endField: 'bankclientLastExportDateLte',
  },
  {
    label: 'Дата нарушения',
    type: 'dateRange',
    startField: 'penaltyDatetimeGte',
    endField: 'penaltyDatetimeLte',
  },

  {
    label: 'Дата загрузки',
    type: 'dateRange',
    startField: 'createdAtGte',
    endField: 'createdAtLte',
  },
  {
    field: 'tmsDriverName',
    label: 'Водитель',
    type: 'text',
  },
  {
    field: 'comment',
    label: 'Комментарий',
    type: 'text',
  },
  {
    field: 'bankclientExported',
    label: 'Платеж выгружен',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
  {
    field: 'paymentIsPaid',
    label: 'Оплачено',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
  {
    field: 'tmsDriverIsNotNull',
    label: 'Водитель установлен',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
  {
    field: 'canDiscount',
    label: 'Возможность оплаты 50% (со скидкой)',
    type: 'boolean',
  },
]
