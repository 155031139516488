import { Chip, ChipProps } from '@mui/joy'
import { useMemo } from 'react'
import { STATUS_VARIANT } from '~/shared/config/constants'

type ChipStatusProps = {
  label: string
  variant: 'green' | 'red' | 'gray' | 'yellow'
} & Omit<ChipProps, 'variant'>

export function ChipStatus({ label, variant, ...props }: ChipStatusProps) {
  const customVariant = useMemo(() => {
    if (variant === 'green') return STATUS_VARIANT['green']
    if (variant === 'red') return STATUS_VARIANT['red']
    if (variant === 'gray') return STATUS_VARIANT['gray']
    if (variant === 'yellow') return STATUS_VARIANT['yellow']
  }, [variant])

  return (
    <Chip
      {...props}
      sx={{
        ...customVariant,
        ...props.sx,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {label}
    </Chip>
  )
}
