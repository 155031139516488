import React, { useCallback, useMemo } from 'react'
import { Penalty } from '~/shared/api'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Filter } from '~/shared/ui/AsyncGrid'
import { useFiltersQueryParams } from '~/shared/ui/AsyncGrid/Filters'
import { FileExportBlob } from '~/shared/ui/FileExport'

type PenaltyExportProps = { filters: Filter[] }

export const PenaltyExport = ({ filters }: PenaltyExportProps) => {
  const [filtersQueryParams] = useFiltersQueryParams(filters)

  const queryFilters = Object.fromEntries(
    Object.entries(filtersQueryParams)
      .map(([key, value]) => [`filter[${key}]`, value?.id || value])
      .filter(([, value]) => Boolean(value)),
  )

  const isFiltersValid = useMemo(
    () =>
      filtersQueryParams.protocolDateGte && filtersQueryParams.protocolDateLte,
    [filtersQueryParams.protocolDateGte, filtersQueryParams.protocolDateLte],
  )

  const errorMessageFn = useCallback(() => {
    snackbarEnqueued({
      message: 'Задайте в фильтре дату от и до протокола',
      variant: 'error' as const,
    })
  }, [])

  const handleExportFn = async () =>
    Penalty.exportPenalty(new URLSearchParams(queryFilters).toString())

  return (
    <FileExportBlob
      title='Отчет в Excel'
      fn={isFiltersValid ? handleExportFn : errorMessageFn}
    />
  )
}
