import { storageGet, storageRemove, storageSet } from '~/shared/lib/storage'

import { BaseAuthStore } from './BaseAuthStore'

type FallbackToken = string | null
/**
 * The default auth store for browsers with auto fallback
 * to runtime/memory if local storage is undefined (eg. in node env).
 */
export class LocalAuthStore extends BaseAuthStore {
  private readonly storageKeyToken: string
  private readonly storageKeyEmail: string
  private readonly storageKeyCurrentUserId: string

  private fallbackToken: FallbackToken = null
  private fallbackEmail: FallbackToken = null
  private fallbackCurrentUserId: FallbackToken = null

  constructor(
    storageKeyToken: string,
    storageKeyEmail: string,
    storageKeyCurrentUserId: string,
  ) {
    super()

    this.storageKeyToken = storageKeyToken
    this.storageKeyEmail = storageKeyEmail
    this.storageKeyCurrentUserId = storageKeyCurrentUserId
  }

  get token(): string {
    const storageByKey = storageGet<string>(this.storageKeyToken)
    return storageByKey || this.fallbackToken || ''
  }

  get email(): string {
    const storageByKey = storageGet<string>(this.storageKeyEmail)
    return storageByKey || this.fallbackEmail || ''
  }

  get currentUserId(): string {
    const storageByKey = storageGet<string>(this.storageKeyCurrentUserId)
    return storageByKey || this.fallbackCurrentUserId || ''
  }

  saveToken(token: string) {
    const { isMemoryUpdate } = storageSet<string>(this.storageKeyToken, token)

    if (isMemoryUpdate) {
      this.fallbackToken = token
    }

    super.saveToken(token)
  }

  saveEmail(email: string) {
    const { isMemoryUpdate } = storageSet<string>(this.storageKeyEmail, email)

    if (isMemoryUpdate) {
      this.fallbackEmail = email
    }

    super.saveEmail(email)
  }

  saveCurrentUserId(currentUserId: string) {
    const { isMemoryUpdate } = storageSet<string>(
      this.storageKeyCurrentUserId,
      currentUserId,
    )

    if (isMemoryUpdate) {
      this.fallbackCurrentUserId = currentUserId
    }

    super.saveCurrentUserId(currentUserId)
  }

  clearToken() {
    storageRemove(this.storageKeyToken)
    this.fallbackToken = null
    super.clearToken()
  }

  clearEmail() {
    storageRemove(this.storageKeyEmail)
    this.fallbackEmail = null
    super.clearEmail()
  }

  clearCurrentUserId() {
    storageRemove(this.storageKeyCurrentUserId)
    this.fallbackCurrentUserId = null
    super.clearCurrentUserId()
  }
}
