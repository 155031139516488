import {
  BooleanParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { UnloadPenaltiesFilterEnum } from '~/entities/UnloadPenalties'

export const useFilters = () => {
  const [filterQuery, setFilterQuery] = useQueryParams({
    isFilteredOrganization: withDefault(BooleanParam, true),
    [UnloadPenaltiesFilterEnum.PAYMENT_AMOUNT]: withDefault(
      NumberParam,
      undefined,
    ),
  })
  return {
    filterQuery,
    setFilterQuery,
  }
}
