import { userOptionsStore } from '~/shared/api'

export const usePermissions = () => {
  const permissions = userOptionsStore.permissions || []

  const checkPermissions = (permission: string) =>
    permissions.includes(permission)

  const canUsersUpdate = checkPermissions('update users')
  const canUsersView = checkPermissions('view users')

  const canRolesUpdate = checkPermissions('update roles')
  const canRolesView = checkPermissions('view roles')

  const canCompanyUpdate = checkPermissions('update companies')
  const canCompaniesView = checkPermissions('view companies')

  const canCompanyBankDetailUpdate = checkPermissions(
    'update company bank detail',
  )
  const canCompanyBankDetailView = checkPermissions('view company bank detail')

  const canPenaltyView = checkPermissions('view penalty')
  const canExportPenaltyToBankClientExport = checkPermissions(
    'export penalty to bank client',
  )

  const canTollRoadView = checkPermissions('view tollroad')
  const canExportTollRoadToBankClientExport = checkPermissions(
    'export tollroad to bank client',
  )

  const canAuditsView = checkPermissions('view audits')

  const canSettingsView = canUsersView || canRolesView

  return {
    canUsersUpdate,
    canUsersView,
    canRolesUpdate,
    canRolesView,
    canCompanyUpdate,
    canCompaniesView,
    canCompanyBankDetailUpdate,
    canCompanyBankDetailView,
    canPenaltyView,
    canExportPenaltyToBankClientExport,
    canAuditsView,
    canSettingsView,
    canTollRoadView,
    canExportTollRoadToBankClientExport,
  }
}
