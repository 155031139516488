import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { debounce } from 'patronum'
import { Penalty, PenaltyExportBankclient } from '~/shared/api'
import { openBlobFile } from '~/shared/lib/file/openBlobFile'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const Gate = createGate()
const domain = createDomain('entities.unloadPenalties.list')

export const exportBankclientTXTFx = domain.createEffect<
  PenaltyExportBankclient,
  void
>({
  async handler(formValues) {
    const res = await Penalty.exportBankclientTXT(formValues)
    openBlobFile(res, '_self')
  },
})

sample({
  clock: exportBankclientTXTFx.failData,
  fn() {
    return {
      message: 'Ошибка выгрузки в банк-клиент TXT',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const exportBankclientEXCELFx = domain.createEffect<
  PenaltyExportBankclient,
  void
>({
  async handler(formValues) {
    const res = await Penalty.exportBankclientEXCEL(formValues)
    openBlobFile(res, '_self')
  },
})

sample({
  clock: exportBankclientEXCELFx.failData,
  fn() {
    return {
      message: 'Ошибка выгрузки в банк-клиент EXCEL',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Amount
export const fetchPenaltiesAmount = domain.createEvent<string[] | undefined>()
export const fetchPenaltiesAmountFx = domain.createEffect<
  string[] | undefined,
  number
>({
  async handler(penaltiesIds) {
    if (!penaltiesIds?.length) return 0
    return await Penalty.getAmountSum(penaltiesIds)
  },
})

export const $amount = domain
  .createStore<number | null>(0)
  .on(fetchPenaltiesAmountFx.doneData, (_, amount) => amount)
  .on(Gate.close, () => 0)

export const debouncedFetchPenaltiesAmount = debounce({
  source: fetchPenaltiesAmount,
  timeout: 400,
})

sample({
  clock: debouncedFetchPenaltiesAmount,
  target: fetchPenaltiesAmountFx,
})

sample({
  clock: fetchPenaltiesAmountFx.failData,
  fn() {
    return {
      message: 'Ошибка получения суммы платежа',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Get ids list
export const fetPenaltiesIdsAllFx = domain.createEffect<
  Record<string, string>,
  Promise<string[]>
>({
  async handler(filters) {
    return await Penalty.getIdList(filters)
  },
})
