import { GridColDef } from '@mui/x-data-grid'
import { ColumnChipList, ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Название',
    field: 'name',
    sortable: false,
    flex: 1,
    minWidth: 300,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.name}</ColumnLink>,
  },
  {
    headerName: 'Права доступа',
    field: 'permissions',
    sortable: false,
    flex: 1,
    minWidth: 300,
    renderCell: ({ value }) => <ColumnChipList items={value} />,
  },
]
