import { useStore, useStoreMap } from 'effector-react'
import { penaltyModel, Form } from '~/entities/Penalty'
import { formatDateTimeForUI } from '~/shared/lib/date'

export function ViewPenalty() {
  const isLoading = useStore(penaltyModel.requestPenaltyFx.pending)

  const defaultValues = useStoreMap(penaltyModel.$penalty, (penalty) => {
    const penaltyDateTime = penalty?.getPenaltyDatetime()

    return {
      ...penalty?.getAttributes(),
      carBrandModel: penalty?.getBrandModel(),
      penaltyDatetime: penaltyDateTime
        ? formatDateTimeForUI(penaltyDateTime)
        : '',
    }
  })

  return <Form isLoading={isLoading} defaultValues={defaultValues} />
}
