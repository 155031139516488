import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'name',
    label: 'Наименование',
    type: 'text',
  },
  {
    field: 'inn',
    label: 'ИНН',
    type: 'text',
  },
  {
    field: 'ogrn',
    label: 'ОГРН',
    type: 'text',
  },
  {
    field: 'kpp',
    label: 'КПП',
    type: 'text',
  },
]
