import { Box } from '@mui/joy'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { IconButton } from '~/shared/ui/Buttons'
import { ArrowNarrowUp } from '~/shared/ui/Icons'

export function ScrollTop() {
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 200,
  })

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        position: 'fixed',
        bottom: 32,
        right: 16,
        opacity: trigger ? 1 : 0,
        transition: 'opacity 0.3s ease',
        zIndex: 1000,
      }}
    >
      <IconButton
        variant='black'
        sx={{
          width: '56px',
          height: '56px',
        }}
      >
        <ArrowNarrowUp />
      </IconButton>
    </Box>
  )
}
