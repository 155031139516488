import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import * as z from 'zod'

import { viewerModel } from '~/entities/viewer'
import { User } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export type FormValues = Omit<
  z.infer<typeof User.schema>,
  'regionsIds' | 'rolesIds'
> & {
  regionsIds: UniqueId[] | Option[]
  rolesIds?: UniqueId[] | Option[]
}

export const Gate = createGate()

export const domain = createDomain('features.user.personalData')

sample({
  clock: Gate.open,
  target: viewerModel.userRequested,
})
