import { CompanyFetchOption } from '~/shared/api'
import { FiltersApi } from '~/shared/ui/AsyncGrid'
import { UnloadTollRoadsFilterEnum } from './Filters'

const { IS_FILTERED_ORGANIZATION, PAYMENT_ORG_INN } = UnloadTollRoadsFilterEnum

export const mapFilterQueryToExternalFilter = (
  filterQuery: Record<string, unknown>,
  companyOption?: CompanyFetchOption,
) => {
  return Object.entries(filterQuery).reduce(
    (filters: FiltersApi, [key, value]) => {
      if (key === IS_FILTERED_ORGANIZATION) {
        return [
          ...filters,
          ...(value && companyOption
            ? [{ key: PAYMENT_ORG_INN, value: companyOption.id }]
            : []),
        ]
      }
      return [...filters, ...(value ? [{ key, value: `${value}` }] : [])]
    },
    [],
  )
}
