import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

import { ErrorBoundary } from '~/shared/ui/ErrorBoundary'

import { Wrapper, ContentWrapper } from './styled'
import { Header } from './ui/Header'

type Props = {
  children?: ReactNode
}

export function MainLayout({ children }: Props) {
  return (
    <Wrapper>
      <Header />
      <ContentWrapper
        sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        <ErrorBoundary>{children || <Outlet />}</ErrorBoundary>
      </ContentWrapper>
    </Wrapper>
  )
}
