import { Alert } from '@mui/joy'
import { useStore } from 'effector-react'
import { Form } from '~/entities/CompanyBankDetail'
import { CompanyBankDetail } from '~/shared/api'
import { isString } from '~/shared/lib/utils'
import {
  $loadingsIds,
  formSubmittedUpdate,
  $companyBankDetailList,
} from './model'

export function EditListCompanyBankDetail() {
  const loadingsIds = useStore($loadingsIds)

  const companyBankDetailList = useStore($companyBankDetailList)
  if (!companyBankDetailList?.length)
    return <Alert>Банковские реквизиты не прикреплены</Alert>

  return (
    <>
      {companyBankDetailList.map((item: CompanyBankDetail) => {
        const companyBankDetailId = item.getApiId()
        const { bankclientMaxDocument, ...attributes } =
          item?.getAttributes() || {}

        const defaultValues = {
          ...attributes,
          bankclientMaxDocument: String(bankclientMaxDocument),
        }

        const key = JSON.stringify(defaultValues)

        const isSubmitting =
          isString(companyBankDetailId) &&
          loadingsIds.includes(companyBankDetailId)

        return (
          <Form
            data-testid='form-company-bank-detail'
            key={key}
            defaultValues={defaultValues}
            isSubmitting={isSubmitting}
            onSuccess={(values, fn) =>
              formSubmittedUpdate({ ...values, companyBankDetailId, fn })
            }
          />
        )
      })}
    </>
  )
}
