import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import * as z from 'zod'
import { companyBankDetailFormModel } from '~/entities/CompanyBankDetail'
import { CompanyBankDetail } from '~/shared/api'

import { createCache } from '~/shared/lib/mapCacheFactory'
import { isString } from '~/shared/lib/utils'

export type SchemaType = z.infer<typeof companyBankDetailFormModel.formSchema>
export type FormValues = Omit<SchemaType, 'companyId'> & {
  companyId?: UniqueId
}

export const domain = createDomain('entities.companyBankDetail')

export const Gate = createGate<{ companyBankDetailId: UniqueId }>()

export const $companyBankDetailId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { companyBankDetailId }) => companyBankDetailId)

export const requestFx = domain.createEffect<UniqueId, CompanyBankDetail>({
  async handler(id) {
    return await fetchCompanyBankDetail(id)
  },
})

export const saveFx = domain.createEffect<CompanyBankDetail, CompanyBankDetail>(
  {
    async handler(companyBankDetail) {
      await companyBankDetail.save()
      return fetchCompanyBankDetail(companyBankDetail.getApiId() as UniqueId)
    },
  },
)

sample({
  clock: $companyBankDetailId,
  filter: isString,
  target: requestFx,
})

const {
  $cache: $companyBankDetailCache,
  useCache: useCompanyBankDetailCache,
  updateCache,
} = createCache<CompanyBankDetail>({
  domain,
  getEntityId: (companyBankDetail) => companyBankDetail.getApiId() as UniqueId,
})
export { $companyBankDetailCache, useCompanyBankDetailCache }

export const $companyBankDetail = combine(
  $companyBankDetailId,
  $companyBankDetailCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

$companyBankDetailCache
  .on(requestFx.doneData, (cache, companyBankDetail) =>
    updateCache(cache, [companyBankDetail]),
  )
  .on(saveFx.doneData, (cache, companyBankDetail) =>
    updateCache(cache, [companyBankDetail], true),
  )

export const $companyBankDetailError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useCompanyBankDetailError = (id: UniqueId) =>
  useStoreMap($companyBankDetailError, (errors) => errors[id])

const fetchCompanyBankDetail = async (id: UniqueId) => {
  const response = await CompanyBankDetail.with('company').find(id)
  return response.getData() as CompanyBankDetail
}
