import { Box, styled } from '@mui/joy'

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`

export const Title = styled(Box)`
  font-size: 36px;
  font-weight: 600;
`

export const SmallTitle = styled(Box)`
  font-size: 18px;
  font-weight: 600;
`
