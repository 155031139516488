import { Grid } from '@mui/joy'
import React from 'react'
import { FormTitle, TextInput } from '~/shared/ui/Form'
import { CalendarIcon } from '~/shared/ui/Icons'
import { Container } from '~/shared/ui/Layout'

type MainProps = {
  loading: boolean
}

export function Violation({ loading }: MainProps) {
  return (
    <Container>
      <FormTitle>Нарушение</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='Дата и время нарушения'
            name='penaltyDatetime'
            readOnly
            skeletonShow={loading}
            startDecorator={<CalendarIcon />}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Код статьи'
            name='penaltyLawArticleCode'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Место нарушения'
            name='penaltyLocation'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Статья'
            name='penaltyLawArticleDescription'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Орган власти'
            name='odpsName'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
