import { useCallback } from 'react'
import { storageGet, storageSet } from '~/shared/lib/storage'
import { ColumnSetting } from './type'

export const useStorageColumns = (storageKey: string) => {
  const setStorageSettings = useCallback(
    (settings: ColumnSetting[]) => {
      storageSet(storageKey, settings)
    },
    [storageKey],
  )

  const getStorageSettings = useCallback(
    () => storageGet<ColumnSetting[]>(storageKey),
    [storageKey],
  )

  return {
    setStorageSettings,
    getStorageSettings,
  }
}
