import { USER_PERMISSIONS_STORAGE_KEY } from '~/shared/config/constants'
import { storageGet, storageRemove, storageSet } from '~/shared/lib/storage'

import { Permissions, UserOptionsMe, UserOptionsStoreType } from './types'

export class UserOptionsStore implements UserOptionsStoreType {
  private readonly storageKeyPermissions = USER_PERMISSIONS_STORAGE_KEY

  private fallbackPermissions: Permissions = null

  get permissions(): Permissions {
    const storageByKey = storageGet<Permissions>(this.storageKeyPermissions)

    return this.fallbackPermissions || storageByKey
  }

  save(options: UserOptionsMe) {
    const { permissions } = options

    storageSet<Permissions>(this.storageKeyPermissions, permissions)

    this.fallbackPermissions = permissions
  }

  clear() {
    storageRemove(this.storageKeyPermissions)

    this.fallbackPermissions = null
  }
}
