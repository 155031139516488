import { myPalette } from '~/shared/lib/theme'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_EMAIL_STORAGE_KEY = 'auth_email'
export const AUTH_CURRENT_USER_ID_STORAGE_KEY = 'auth_current_user_id'
export const USER_PERMISSIONS_STORAGE_KEY = 'user_permissions'
export const MIN_COLUMN_WIDTH = 90

export type Option = { id: string | number; label: string | number }
export type OptionEnum<T> = { id: T; label: string }

export const STATUS_VARIANT = {
  green: {
    backgroundColor: myPalette.green['100'],
    color: myPalette.green['600'],
  },
  red: {
    backgroundColor: myPalette.red['100'],
    color: myPalette.red['600'],
  },
  gray: {
    backgroundColor: myPalette.gray['100'],
    color: myPalette.gray['700'],
  },
  yellow: {
    backgroundColor: myPalette.yellow['100'],
    color: myPalette.yellow['600'],
  },
}
