import { styled, Textarea as JoyTextarea } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Textarea = styled(JoyTextarea)<{
  readOnly?: boolean
}>(({ readOnly }) => ({
  margin: '2px 0',
  background: myPalette.white.main,
  backgroundColor: myPalette.white.main,
  '--Input-decoratorColor': myPalette.white.main,
  '--Input-radius': '12px',
  border: `1px solid ${myPalette.gray['400']}`,
  transition: 'background 0.3s, border-color 0.3s',

  '&.mui-disabled': {
    border: `1px solid ${myPalette.gray['100']}`,
  },

  '&': {
    ...(readOnly && {
      background: `${myPalette.gray['100']}`,
      borderColor: 'transparent',
    }),
  },

  '&.Mui-focused:before': {
    boxShadow: `0 0 0 1px ${myPalette.brand['300']}, 0 0 0 4px ${myPalette.purple['100']}`,
  },

  '&.Mui-error': {
    border: `1px solid ${myPalette.red['700']}`,

    '&.Mui-focused:before': {
      boxShadow: `0 0 0 1px ${myPalette.red['600']}, 0 0 2px 2px ${myPalette.red['700']}`,
    },
  },
}))
