import { useStore } from 'effector-react'
import { Form } from '~/entities/Employee'
import { formSubmitted, createFx } from './model'

export const AddEmployee = () => {
  const isSubmitting = useStore(createFx.pending)

  return (
    <Form isSubmitting={isSubmitting} isCreating onSuccess={formSubmitted} />
  )
}
