import { useCallback } from 'react'
import { storageGet, storageSet } from '~/shared/lib/storage'
import { IconButton } from '~/shared/ui/Buttons'
import { SpacingWidthIcon } from '~/shared/ui/Icons'
import { useGridContext } from '../context'

import { ColumnSetting, mapSettingsToColumns } from '../SettingsColumns'

export function ResetResize() {
  const {
    defaultColumns,
    storageKeyResize,
    storageKeySettings,
    updateColumns,
  } = useGridContext()

  const handleResetResize = useCallback(() => {
    const storageSettings = storageGet<ColumnSetting[]>(storageKeySettings)
    updateColumns([])

    const timeout = setTimeout(() => {
      updateColumns(mapSettingsToColumns(storageSettings, defaultColumns))
      clearTimeout(timeout)
    }, 0)

    storageSet(storageKeyResize, [])
  }, [defaultColumns, storageKeyResize, storageKeySettings, updateColumns])

  return (
    <IconButton
      tooltipProps={{ title: 'Сбросить ширину колонок по умолчанию!' }}
      onClick={handleResetResize}
    >
      <SpacingWidthIcon />
    </IconButton>
  )
}
