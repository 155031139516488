import { createContext, useContext } from 'react'
import { Columns } from './types'

export type GridContextType = {
  defaultColumns: Columns
  gridKey: string
  updateColumns: (columns: Columns) => void
  columns: Columns
  storageKeyResize: string
  storageKeySettings: string
}

export const GridContext = createContext<GridContextType>({} as GridContextType)

export const useGridContext = () => useContext(GridContext)
