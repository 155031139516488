import { ReactNode } from 'react'
import { Outlet } from 'react-router-dom'

type Props = {
  children?: ReactNode
}

export function MinimalLayout({ children }: Props) {
  return <>{children || <Outlet />}</>
}
