import { Grid } from '@mui/joy'
import React from 'react'
import { FormTitle, NumericInput } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type PaymentProps = {
  loading: boolean
}

export function Payment({ loading }: PaymentProps) {
  return (
    <Container>
      <FormTitle>Оплата</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <NumericInput
            label='К оплате'
            name='paymentAmount'
            readOnly
            skeletonShow={loading}
            numericProps={{
              suffix: ' руб',
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
