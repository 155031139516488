import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ViewTollRoad } from '~/features/TollRoad/ViewTollRoad'
import { tollRoadModel } from '~/entities/TollRoad'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { PageContainer, Container, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Осн. информация', anchor: 'main' },
  { label: 'Фото', anchor: 'photos' },
  { label: 'Оплата', anchor: 'payment' },
  { label: 'Получатель платежа', anchor: 'paymentReceiver' },
  { label: 'Выгрузка', anchor: 'unloadingData' },
]

export function ViewTollRoadPage() {
  const params = useParams<{ tollRoadId: string }>()
  const tollRoadId = params.tollRoadId as string

  useGate(tollRoadModel.Gate, { tollRoadId })

  const tollRoad = useStore(tollRoadModel.$tollRoad)
  const isLoading = useStore(tollRoadModel.requestTollRoadFx.pending)

  const roadName = tollRoad?.getRoadName()
  const eventDatetime = tollRoad?.getEventDatetime()

  const title = `Проезд ${roadName} от ${
    eventDatetime ? formatDateTimeForUI(eventDatetime) : ''
  }`

  const tollRoadError = tollRoadModel.useTollRoadError(tollRoadId)

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Проезды ЦКАД и МСД', link: '/toll-roads' },
      { title: title },
    ],
    [title],
  )

  if (tollRoadError) {
    return <NotFound message='Не удалось загрузить проезд' />
  }

  return (
    <PageContainer title={title}>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{title}</PageTitle>
      </Container>

      <ViewTollRoad />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
