import { useState } from 'react'
import { useQueryParam, withDefault, NumberParam } from 'use-query-params'

export const PAGE_QUERY = 1
const PAGE_SIZE_QUERY = 10

export const useMemoryQuery = (memoryLocal?: boolean) => {
  const [pageQuery, setPageQuery] = useQueryParam(
    'page',
    withDefault(NumberParam, PAGE_QUERY),
    { updateType: 'replaceIn' },
  )

  const [pageSizeQuery, setPageSizeQuery] = useQueryParam(
    'pageSize',
    withDefault(NumberParam, PAGE_SIZE_QUERY),
    { updateType: 'replaceIn' },
  )

  const [page, setPage] = useState(PAGE_QUERY)
  const [pageSize, setPageSize] = useState(PAGE_SIZE_QUERY)

  return {
    page: memoryLocal ? page : pageQuery,
    setPage: memoryLocal ? setPage : setPageQuery,
    pageSize: memoryLocal ? pageSize : pageSizeQuery,
    setPageSize: memoryLocal ? setPageSize : setPageSizeQuery,
  }
}
