import React, { useCallback, useMemo } from 'react'
import { TollRoad } from '~/shared/api'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Filter } from '~/shared/ui/AsyncGrid'
import { useFiltersQueryParams } from '~/shared/ui/AsyncGrid/Filters'
import { FileExportBlob } from '~/shared/ui/FileExport'

type TollRoadsExportProps = { filters: Filter[] }

export const TollRoadsExport = ({ filters }: TollRoadsExportProps) => {
  const [filtersQueryParams] = useFiltersQueryParams(filters)

  const queryFilters = Object.fromEntries(
    Object.entries(filtersQueryParams)
      .map(([key, value]) => [`filter[${key}]`, value?.id || value])
      .filter(([, value]) => Boolean(value)),
  )

  const isFiltersValid = useMemo(
    () => filtersQueryParams.createdAtGte && filtersQueryParams.createdAtLte,
    [filtersQueryParams.createdAtGte, filtersQueryParams.createdAtLte],
  )

  const errorMessageFn = useCallback(() => {
    snackbarEnqueued({
      message: 'Задайте в фильтре дату от и до загрузки в систему',
      variant: 'error' as const,
    })
  }, [])

  const handleExportFn = async () =>
    TollRoad.export(new URLSearchParams(queryFilters).toString())

  return (
    <FileExportBlob
      title='Отчет в Excel'
      fn={isFiltersValid ? handleExportFn : errorMessageFn}
    />
  )
}
