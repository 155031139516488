import { isBrowser } from '~/shared/lib/isBrowser'

export const storageGet = <T>(key: string): T | null => {
  if (isBrowser()) {
    try {
      return JSON.parse(window.localStorage.getItem(key) || '')
    } catch (e) {
      return null
    }
  }

  return null
}

export const storageSet = <T>(
  key: string,
  value: T,
): { isMemoryUpdate: boolean } => {
  const isLocal = isBrowser()
  if (isLocal) window.localStorage.setItem(key, JSON.stringify(value))

  return { isMemoryUpdate: !isLocal }
}

export const storageRemove = (key: string) => {
  if (isBrowser()) window.localStorage.removeItem(key)
}
