import { difference } from 'lodash'
import { Column, Columns } from '../types'
import { FormValues, ColumnSetting } from './type'

export const reorder = (
  settings: ColumnSetting[],
  startIndex: number,
  endIndex: number,
): ColumnSetting[] => {
  const result = Array.from(settings)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const mapColumnsToSettings = (columns: Columns): ColumnSetting[] =>
  (columns || [])
    .map(({ headerName, field }) => ({
      headerName,
      field,
      isActive: true,
    }))
    .filter((setting): setting is ColumnSetting =>
      Boolean(setting.headerName && setting.field),
    )

export const mapSettingsToFormValues = (
  settings: ColumnSetting[],
): FormValues =>
  settings.reduce(
    (data, setting) => ({
      ...data,
      [setting.field]: setting.isActive,
    }),
    {},
  )

export const isDifferentColumns = (
  columns: Columns,
  settings: ColumnSetting[],
): boolean => {
  const columnsHeaderNames = columns.map(({ headerName }) => headerName)
  const settingsHeaderNames = settings.map(({ headerName }) => headerName)
  const columnsHeaderFields = columns.map(({ field }) => field)
  const settingsHeaderFields = settings.map(({ field }) => field)

  return Boolean(
    difference(columnsHeaderNames, settingsHeaderNames).length ||
      difference(settingsHeaderNames, columnsHeaderNames).length ||
      difference(columnsHeaderFields, settingsHeaderFields).length ||
      difference(settingsHeaderFields, columnsHeaderFields).length,
  )
}

export const mapSettingsToColumns = (
  settings: ColumnSetting[] | null,
  columns: Columns,
) => {
  if (!settings?.length || isDifferentColumns(columns, settings)) return columns

  const sortedColumn = settings
    .map(({ field }) => columns.find((column) => column.field === field))
    .filter((column): column is Column => !!column)

  return sortedColumn.map((column) => ({
    ...column,
    isActive: settings?.find((setting) => setting.field === column.field)
      ?.isActive,
  }))
}
