import { useStore, useStoreMap } from 'effector-react'

import { $user, fetchUserFx } from '~/entities/viewer/model'
import { Form } from './ui/Form'

export function PersonalData() {
  const defaultValues = useStoreMap($user, (user) => ({
    ...user?.getAttributes(),
  }))
  const isFetchUserPending = useStore(fetchUserFx.pending)

  return <Form defaultValues={defaultValues} isLoading={isFetchUserPending} />
}
