import {
  FormControl,
  FormHelperText,
  FormLabel,
  Skeleton,
  InputProps,
} from '@mui/joy'

import { Controller } from 'react-hook-form'
import { Input } from '../ui/styled'

type TextInputProps = {
  label?: string
  name: string
  skeletonShow?: boolean
} & Omit<InputProps, 'name' | 'slotProps'>

export function TextInput({
  label,
  name,
  skeletonShow,
  ...inputProps
}: TextInputProps) {
  return (
    <Controller
      name={name}
      defaultValue=''
      render={({ field, fieldState: { error, invalid } }) => (
        <FormControl error={invalid}>
          {label && <FormLabel>{label}</FormLabel>}
          <Input
            fullWidth
            size='lg'
            {...inputProps}
            {...field}
            error={invalid}
            value={field.value || ''}
            slotProps={{
              ...(skeletonShow ? { root: { component: Skeleton } } : {}),
            }}
          />
          {invalid && (
            <FormHelperText data-testid={`error-message-${label}`}>
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
