import { Box, styled } from '@mui/joy'

export const AmountWrapper = styled(Box)`
  display: flex;
  width: 300px;
`

export const AmountTitle = styled('span')`
  white-space: nowrap;
  margin-right: 6px;
`
export const Amount = styled('span')`
  position: relative;
  display: inline-block;
  width: 100%;
  font-weight: 600;
`
