import { AccordionGroup, Accordion, AccordionDetails } from '@mui/joy'
import { useStore } from 'effector-react'
import { useCallback } from 'react'
import { Form } from '~/entities/CompanyBankDetail'
import { companyBankDetailFormModel } from '~/entities/CompanyBankDetail'
import { createFx, formSubmittedCreate } from './model'

type AddCompanyBankDetailProps = {
  isVisible: boolean
  hiddenFn: () => void
}

export function AddCompanyBankDetail({
  isVisible,
  hiddenFn,
}: AddCompanyBankDetailProps) {
  const isSubmitting = useStore(createFx.pending)

  const handleCompanyBankDetailCreate = useCallback(
    (formValues: companyBankDetailFormModel.FormValues) => {
      formSubmittedCreate({ formValues, fn: hiddenFn })
    },
    [hiddenFn],
  )

  return (
    <AccordionGroup data-testid='add-company-bank-detail'>
      <Accordion expanded={isVisible} sx={{ minHeight: 0, padding: 0 }}>
        <AccordionDetails>
          <Form
            key={JSON.stringify(isVisible)}
            isCreating
            isSubmitting={isSubmitting}
            onCancel={hiddenFn}
            onSuccess={handleCompanyBankDetailCreate}
          />
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  )
}
