import { Link as JoyLink, LinkProps } from '@mui/joy'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/lib/theme'

type ColumnLinkProps = LinkProps & {
  children: string | ReactNode
} & ({ to: string; href?: never } | { href: string; to?: never })

export function ColumnLink({ children, to, ...props }: ColumnLinkProps) {
  const localLink = to ? { to, component: Link } : {}

  return (
    <JoyLink
      {...props}
      {...localLink}
      sx={{
        ...props?.sx,
        color: myPalette.brand['700'],
        textDecoration: 'none !important',
        textOverflow: 'ellipsis !important',
        overflow: 'hidden !important',
        display: 'inline-block',
      }}
    >
      {children}
    </JoyLink>
  )
}
