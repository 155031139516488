import {
  DragDropContext,
  Droppable,
  OnDragEndResponder,
} from '@hello-pangea/dnd'
import { List } from '@mui/joy'
import * as React from 'react'
import { DraggableItem } from './DraggableItem'
import { ColumnSetting } from './type'

export type DraggableListProps = {
  settings: ColumnSetting[]
  onDragEnd: OnDragEndResponder
}

export function DraggableList({ settings, onDragEnd }: DraggableListProps) {
  const activeSettings = settings.filter(({ isActive }) => isActive)
  const lastSetting = activeSettings.length === 1 ? activeSettings[0] : null

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-list'>
        {({ innerRef, droppableProps, placeholder }) => (
          <List ref={innerRef} {...droppableProps}>
            {settings.map((setting, index) => (
              <DraggableItem
                disabled={lastSetting?.field === setting.field}
                setting={setting}
                index={index}
                key={setting.field}
              />
            ))}
            {placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}
