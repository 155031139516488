import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { MainLayout } from '~/widgets/layout/main'
import { MinimalLayout } from '~/widgets/layout/minimal'
import { ProtectedPage } from '~/features/Auth/protected-page'
import NotFoundPage from './404'
import { AddCompanyPage } from './Companies/AddCompany'
import { ListCompanyPage } from './Companies/ListCompany'
import { ViewCompanyPage } from './Companies/ViewCompany'
import { usePermissionsPage } from './hooks/usePermissionsPage'
import { LoginPage } from './Login'
import { NoAccess } from './NoAccess'
import { ListPenaltiesPage } from './Penalty/ListPenaltiesPage'
import { ViewPenaltyPage } from './Penalty/ViewPenaltyPage'
import SettingsPage from './Settings'
import { ListTollRoadsPage } from './TollRoad/ListTollRoadsPage'
import { ViewTollRoadPage } from './TollRoad/ViewTollRoadPage'
import { ListUnloadPenaltiesPage } from './UnloadPenalties/List'
import { UnloadTollRoadsPage } from './UnloadTollRoads/List'
import UserPersonalDataPage from './UserPersonalData'

export const Routing: React.FC = () => {
  const location = useLocation()
  const { notPermissions } = usePermissionsPage()

  const [notPermissionsPage, setNotPermissionsPage] = useState(notPermissions)

  // Найти лучший способ для ререндера роутов
  useEffect(() => {
    if (!isEqual(notPermissions, notPermissionsPage)) {
      setNotPermissionsPage(notPermissions)
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Routes>
      <Route element={<ProtectedPage />}>
        <Route element={<MainLayout />}>
          {notPermissionsPage?.map((path) => (
            <Route key={path} path={path} element={<NoAccess />} />
          ))}

          <Route path='/' element={<Navigate to='/penalties' />} />

          <Route path='/penalties' element={<ListPenaltiesPage />} />
          <Route
            path='/penalties/unload'
            element={<ListUnloadPenaltiesPage />}
          />
          <Route path='/penalties/:penaltyId' element={<ViewPenaltyPage />} />

          <Route path='/toll-roads' element={<ListTollRoadsPage />} />
          <Route path='/toll-roads/unload' element={<UnloadTollRoadsPage />} />
          <Route
            path='/toll-roads/:tollRoadId'
            element={<ViewTollRoadPage />}
          />

          <Route path='/companies' element={<ListCompanyPage />} />
          <Route path='/companies/new' element={<AddCompanyPage />} />
          <Route path='/companies/:companyId' element={<ViewCompanyPage />} />

          <Route path='/settings/*' element={<SettingsPage />} />

          <Route
            path='/user-personal-data'
            element={<UserPersonalDataPage />}
          />
        </Route>
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='/login' element={<LoginPage />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
