import { combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { $permissions } from '~/entities/Permission/model'
import { roleFormModel } from '~/entities/Role'
import { AxiosErrorType, Role } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('features.roles.add')
export const Gate = createGate<{ id: UniqueId }>()

export const createFx = domain.createEffect<
  roleFormModel.FormSuccessValues,
  Role,
  AxiosErrorType
>({
  async handler(values: roleFormModel.FormSuccessValues) {
    const { permissionsIds, ...formData } = values
    const role = new Role(formData)

    if (Array.isArray(permissionsIds)) role.setPermissions(permissionsIds)

    const result = await role.save()
    return result.getModel() as Role
  },
})

export const formSubmitted =
  domain.createEvent<roleFormModel.FormSuccessValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Роль создана',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $createRoleModel = combine(
  $permissions,
  (permissions): roleFormModel.FormValues => {
    const mappedDefaultPermissions = (permissions || []).reduce(
      (objPermission: roleFormModel.FormPermissionValues, permission) => ({
        ...objPermission,
        [permission.id]: false,
      }),
      {},
    )
    return {
      name: '',
      ...mappedDefaultPermissions,
    } as roleFormModel.FormValues
  },
)
