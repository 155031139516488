import { zodResolver } from '@hookform/resolvers/zod'
import {
  AccordionDetails,
  Accordion,
  AccordionGroup,
  Divider,
  Grid,
} from '@mui/joy'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePermissions } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { FormProvider, NumericInput, TextInput } from '~/shared/ui/Form'
import { formSchema, FormValues } from './model'

type FormProps = {
  isCreating?: boolean
  isSubmitting?: boolean
  defaultValues?: Partial<FormValues>
  onSuccess: (formValues: FormValues, fn: () => void) => void
  onCancel?: () => void
}

export function Form({
  isCreating,
  isSubmitting,
  defaultValues,
  onSuccess,
  onCancel,
  ...props
}: FormProps) {
  const { canCompanyBankDetailUpdate } = usePermissions()
  const [isButtonsShow, setIsButtonsShow] = useState<boolean>(
    isCreating || false,
  )

  const disabled = !isButtonsShow || isSubmitting || !canCompanyBankDetailUpdate

  const handleButtonsShow = () => {
    setIsButtonsShow(true)
  }
  const handleButtonsHidden = () => {
    setIsButtonsShow(false)
  }

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  })

  const { reset } = form

  const handleCancel = () => {
    reset()
    onCancel?.()
    handleButtonsHidden()
  }

  const handleSuccess = (formValues: FormValues) => {
    onSuccess(formValues, handleButtonsHidden)
  }

  return (
    <FormProvider form={form} onSuccess={handleSuccess} {...props}>
      <Grid
        container
        spacing={2.5}
        sx={{ marginBottom: '6px' }}
        onDoubleClick={handleButtonsShow}
      >
        <Grid xs={6}>
          <TextInput
            label='Наименование реквизитов'
            name='title'
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Название банка'
            name='bankName'
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <NumericInput
            label='Расчётный счёт'
            name='accountNumber'
            numericProps={{
              thousandSeparator: '',
              decimalScale: 0,
            }}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <NumericInput
            label='Корреспондентский счёт'
            name='corrAccountNumber'
            numericProps={{
              thousandSeparator: '',
              decimalScale: 0,
            }}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <NumericInput
            label='БИК'
            name='bik'
            numericProps={{
              thousandSeparator: '',
              decimalScale: 0,
            }}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <NumericInput
            label='Макс. кол-во ПП в выгрузке'
            name='bankclientMaxDocument'
            numericProps={{
              thousandSeparator: '',
              decimalScale: 0,
            }}
            readOnly={disabled}
          />
        </Grid>
        <AccordionGroup>
          <Accordion expanded={isButtonsShow} sx={{ minHeight: 0, padding: 0 }}>
            <AccordionDetails>
              <Grid
                xs={12}
                direction='row'
                hidden={!canCompanyBankDetailUpdate}
              >
                <Button
                  variant='green'
                  type='submit'
                  loading={isSubmitting}
                  sx={{ marginRight: '24px' }}
                >
                  {isCreating ? 'Создать' : 'Сохранить'}
                </Button>
                <Button
                  variant='red'
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  Отменить
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
        <Grid xs={12}>
          <Divider sx={{ margin: '16px 0' }} />
        </Grid>
      </Grid>
    </FormProvider>
  )
}
