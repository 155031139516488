import { MIN_COLUMN_WIDTH } from '~/shared/config/constants'
import { Columns } from '../types'
import { ColumnSize } from './type'

export const setStyles = (
  element: HTMLElement,
  left: number,
  opacity: number,
) => {
  element.style.left = `${left}px`
  element.style.opacity = `${opacity}`
}

export const normalizeColumnWidth = (columnWidth: number) => {
  if (columnWidth < MIN_COLUMN_WIDTH) return MIN_COLUMN_WIDTH

  return columnWidth
}

export const mapColumnSizesToColumns = (
  columnSizes: ColumnSize[] | null,
  columns: Columns,
) => {
  if (!columnSizes?.length) return columns
  return columns.map((columnSize) => {
    const { width } =
      (columnSizes || []).find(({ field }) => columnSize.field === field) || {}

    return {
      ...columnSize,
      ...(width ? { minWidth: width, maxWidth: width } : {}),
    }
  })
}
