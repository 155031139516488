import React from 'react'
import { ListCompanies } from 'src/features/Companies/ListCompanies'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'

export function ListCompanyPage() {
  return (
    <PageContainer title='Организации'>
      <Container isHeader>
        <PageTitle>Организации</PageTitle>
      </Container>

      <Container>
        <ListCompanies />
      </Container>
    </PageContainer>
  )
}
