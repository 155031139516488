import { Box, styled } from '@mui/joy'

export const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 0 32px;
`

export const ContentWrapper = styled(Box)`
  margin: 0 auto;
  width: 80%;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  flex: 1;
`
