import * as z from 'zod'

import { User } from '~/shared/api'

export const schema = User.schema.pick({
  email: true,
  password: true,
})

export type FormValues = z.infer<typeof schema>
