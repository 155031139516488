export function FileIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6666 1.89128V5.3334C11.6666 5.80011 11.6666 6.03346 11.7575 6.21172C11.8374 6.36853 11.9649 6.49601 12.1217 6.5759C12.2999 6.66673 12.5333 6.66673 13 6.66673H16.4421M11.6666 14.1667H6.66665M13.3333 10.8333H6.66665M16.6666 8.32353V14.3333C16.6666 15.7335 16.6666 16.4335 16.3942 16.9683C16.1545 17.4387 15.772 17.8212 15.3016 18.0609C14.7668 18.3333 14.0668 18.3333 12.6666 18.3333H7.33331C5.93318 18.3333 5.23312 18.3333 4.69834 18.0609C4.22793 17.8212 3.84548 17.4387 3.6058 16.9683C3.33331 16.4335 3.33331 15.7335 3.33331 14.3333V5.66667C3.33331 4.26654 3.33331 3.56647 3.6058 3.0317C3.84548 2.56129 4.22793 2.17884 4.69834 1.93916C5.23312 1.66667 5.93318 1.66667 7.33331 1.66667H10.0098C10.6213 1.66667 10.927 1.66667 11.2147 1.73575C11.4698 1.79699 11.7137 1.898 11.9374 2.03507C12.1897 2.18968 12.4058 2.40587 12.8382 2.83824L15.4951 5.4951C15.9275 5.92748 16.1436 6.14367 16.2982 6.39596C16.4353 6.61964 16.5363 6.8635 16.5976 7.11859C16.6666 7.40631 16.6666 7.71205 16.6666 8.32353Z'
        stroke='#3525E5'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
