export function SpacingWidthIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 17.5V2.5M2.5 17.5V2.5M5.41667 10H14.5833M14.5833 12.5L14.5833 7.5M5.41667 12.5L5.41667 7.5'
        stroke='#B42318'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
