export function HistoryEduIcon() {
  return (
    <svg
      width='16'
      height='20'
      viewBox='0 0 16 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.3333 3.33335C12.1082 3.33335 12.4957 3.33335 12.8136 3.41854C13.6764 3.64971 14.3502 4.32358 14.5814 5.18631C14.6666 5.50422 14.6666 5.89171 14.6666 6.66669V14.3334C14.6666 15.7335 14.6666 16.4336 14.3941 16.9683C14.1544 17.4387 13.772 17.8212 13.3016 18.0609C12.7668 18.3334 12.0667 18.3334 10.6666 18.3334H5.33325C3.93312 18.3334 3.23305 18.3334 2.69828 18.0609C2.22787 17.8212 1.84542 17.4387 1.60574 16.9683C1.33325 16.4336 1.33325 15.7335 1.33325 14.3334V6.66669C1.33325 5.89171 1.33325 5.50422 1.41844 5.18631C1.64961 4.32358 2.32347 3.64971 3.1862 3.41854C3.50412 3.33335 3.89161 3.33335 4.66659 3.33335M5.49992 12.5L7.16659 14.1667L10.9166 10.4167M5.99992 5.00002H9.99992C10.4666 5.00002 10.7 5.00002 10.8782 4.90919C11.035 4.8293 11.1625 4.70181 11.2424 4.54501C11.3333 4.36675 11.3333 4.1334 11.3333 3.66669V3.00002C11.3333 2.53331 11.3333 2.29995 11.2424 2.12169C11.1625 1.96489 11.035 1.83741 10.8782 1.75751C10.7 1.66669 10.4666 1.66669 9.99992 1.66669H5.99992C5.53321 1.66669 5.29985 1.66669 5.12159 1.75751C4.96479 1.83741 4.83731 1.96489 4.75741 2.12169C4.66659 2.29995 4.66659 2.53331 4.66659 3.00002V3.66669C4.66659 4.1334 4.66659 4.36675 4.75741 4.54501C4.83731 4.70181 4.96479 4.8293 5.12159 4.90919C5.29985 5.00002 5.53321 5.00002 5.99992 5.00002Z'
        stroke='#3525E5'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
