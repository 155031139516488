import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { Company } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListCompanies = () => {
  const { canCompanyUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = Company.limit(pageSize)

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((company: Company) => ({
        ...company.getAttributes(),
        id: company?.getApiId() as UniqueId,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='company-list'
      smallTitle='Организации'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      headerExtra={
        <Button startDecorator={<PlusIcon />} to='new' show={canCompanyUpdate}>
          Добавить
        </Button>
      }
    />
  )
}
