import { useQueryClient } from '@tanstack/react-query'
import { useGate, useStore } from 'effector-react'
import { omit } from 'lodash'
import React, { useMemo } from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { filters } from '~/features/TollRoad/ListTollRoads'
import {
  List,
  UnloadTollRoadsFilterEnum,
  unloadTollRoadModel,
  unloadTollRoadFormModel,
} from '~/entities/UnloadTollRoads'
import { TollRoadExportBankclient } from '~/shared/api'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { useFiltersQueryParams } from '~/shared/ui/AsyncGrid/Filters'
import { $companyOption, Gate, fetchCompanyFx } from './model'

const mapFormValues = (
  formValues: unloadTollRoadFormModel.FormValues,
  selectedTollRoadsIds: string[],
): TollRoadExportBankclient => ({
  startNumber: String(formValues.startNumber),
  companyBankDetailId: formValues?.companyBankDetailOption as string,
  tollRoadsIds: selectedTollRoadsIds,
})

export const ListUnloadTollRoads = () => {
  const queryClient = useQueryClient()

  const [defaultFilters] = useFiltersQueryParams(filters)
  const [frozenDateTime] = useQueryParam(
    'frozenDateTime',
    withDefault(StringParam, ''),
  )

  useGate(Gate, { inn: defaultFilters?.paymentOrgInn?.id })
  const companyPending = useStore(fetchCompanyFx.pending)

  const handleListUpdate = () => {
    queryClient.invalidateQueries({
      queryKey: ['unload-toll-roads-list'],
    })
  }

  const mappedDefaultFilters = useMemo(() => {
    return Object.entries(defaultFilters).reduce(
      (values: Record<string, string>, [key, value]) => {
        if (!value) return values
        return {
          ...values,
          [key]: String(value?.id ? value.id : value),
        }
      },
      {},
    )
  }, [defaultFilters])

  const companyOption = useStore($companyOption)
  const isSubmittingTXT = useStore(
    unloadTollRoadModel.exportBankclientTXTFx.pending,
  )
  const isSubmittingEXCEL = useStore(
    unloadTollRoadModel.exportBankclientEXCELFx.pending,
  )

  const handleOnSuccessTXT = async (
    formValues: unloadTollRoadFormModel.FormValues,
    selectedTollRoadsIds?: string[],
  ) => {
    if (!selectedTollRoadsIds?.length) {
      snackbarEnqueued({
        message: 'Для выгрузки TXT выберите проезды',
        variant: 'warning',
      })
      return
    }

    await unloadTollRoadModel.exportBankclientTXTFx(
      mapFormValues(formValues, selectedTollRoadsIds),
    )
    handleListUpdate()
  }

  const handleOnSuccessEXCEL = async (
    formValues: unloadTollRoadFormModel.FormValues,
    selectedTollRoadsIds?: string[],
  ) => {
    if (!selectedTollRoadsIds?.length) {
      snackbarEnqueued({
        message: 'Для выгрузки EXCEL выберите проезды',
        variant: 'warning',
      })
      return
    }
    await unloadTollRoadModel.exportBankclientEXCELFx(
      mapFormValues(formValues, selectedTollRoadsIds),
    )
    handleListUpdate()
  }

  return (
    <List
      companyPending={companyPending}
      companyDefaultValue={companyOption}
      defaultFilters={omit(
        mappedDefaultFilters,
        UnloadTollRoadsFilterEnum.PAYMENT_ORG_INN,
      )}
      onSuccessTXT={handleOnSuccessTXT}
      onSuccessEXCEL={handleOnSuccessEXCEL}
      isSubmittingTXT={isSubmittingTXT}
      isSubmittingEXCEL={isSubmittingEXCEL}
      frozenDateTime={frozenDateTime}
    />
  )
}
