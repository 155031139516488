import { Grid } from '@mui/joy'
import { debounce } from 'lodash'
import { useForm } from 'react-hook-form'
import { UnloadTollRoadsFilterEnum } from '~/entities/UnloadTollRoads'
import { sanitizePriceNumber } from '~/shared/lib/utils'
import { FormProvider, NumericInput, Switch } from '~/shared/ui/Form'
import { useFilters } from '../useFilters'

type FormValues = {
  [UnloadTollRoadsFilterEnum.IS_FILTERED_ORGANIZATION]: boolean
  [UnloadTollRoadsFilterEnum.PAYMENT_AMOUNT]: number
}

export function Filters() {
  const { filterQuery, setFilterQuery } = useFilters()

  const debouncedSetFilterQuery = debounce(setFilterQuery, 400)

  const form = useForm<FormValues>({
    defaultValues: filterQuery,
  })

  const { watch } = form

  watch((data, { type }) => {
    if (type !== 'change') return
    debouncedSetFilterQuery(
      {
        [UnloadTollRoadsFilterEnum.IS_FILTERED_ORGANIZATION]:
          data[UnloadTollRoadsFilterEnum.IS_FILTERED_ORGANIZATION],

        [UnloadTollRoadsFilterEnum.PAYMENT_AMOUNT]: sanitizePriceNumber(
          data[UnloadTollRoadsFilterEnum.PAYMENT_AMOUNT] || 0,
        ),
      },
      'replaceIn',
    )
  })

  return (
    <FormProvider form={form}>
      <Grid container spacing={2.5} alignItems='center'>
        <Grid xs={2.4}>
          <Switch
            label='Фильтровать по организации'
            name={UnloadTollRoadsFilterEnum.IS_FILTERED_ORGANIZATION}
          />
        </Grid>
        <Grid xs={2.4}>
          <NumericInput
            placeholder='Сумма к оплате'
            name={UnloadTollRoadsFilterEnum.PAYMENT_AMOUNT}
            numericProps={{
              thousandSeparator: '',
              suffix: ' руб',
            }}
          />
        </Grid>
      </Grid>
    </FormProvider>
  )
}
