import { z } from 'zod'
import {
  dateSchema,
  innRequiredSchema,
  kppRequiredSchema,
  ogrnRequiredSchema,
} from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
  inn: innRequiredSchema,
  ogrn: ogrnRequiredSchema,
  kpp: kppRequiredSchema,
  payment: z.boolean(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type CompanyAttributes = z.infer<typeof schema>

export type CompanyFetchOption = {
  id: string
  label: string
  companyId: string
}

export class Company extends ApiModel<typeof schema> {
  static jsonApiType = 'companies'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }
  getInn(): string {
    return this.getAttribute('inn')
  }

  getOptionWithInn(): CompanyFetchOption {
    return {
      id: this.getInn(),
      label: this.getName(),
      companyId: this.getApiId() as UniqueId,
    }
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  static async fetchOptionsWithInn(search: string) {
    const response = await Company.where('name', search).get(1)
    return response.getData().map((o) => o.getOptionWithInn())
  }

  static async fetchOptionsOfPayer(search: string) {
    const response = await Company.where('name', search)
      .where('payment', 'true')
      .get(1)
    return response.getData().map((o) => o.getOptionWithInn())
  }

  static async fetchOptions(search: string) {
    const response = await Company.where('name', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
