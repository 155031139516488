import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import { TollRoad } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.tollRoad')

export const Gate = createGate<{ tollRoadId: UniqueId }>()

export const $tollRoadId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { tollRoadId }) => tollRoadId)

export const requestTollRoadFx = domain.createEffect<UniqueId, TollRoad>({
  async handler(id) {
    return await fetchTollRoad(id)
  },
})

const fetchTollRoad = async (id: UniqueId) => {
  const response = await TollRoad.find(id)
  return response.getData() as TollRoad
}

sample({
  clock: $tollRoadId,
  filter: isString,
  target: requestTollRoadFx,
})

const {
  $cache: $tollRoadCache,
  useCache: useTollRoadCache,
  updateCache,
} = createCache<TollRoad>({
  domain,
  getEntityId: (tollRoad) => tollRoad.getApiId() as UniqueId,
})
export { $tollRoadCache, useTollRoadCache }

export const $tollRoad = combine($tollRoadId, $tollRoadCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

$tollRoadCache.on(requestTollRoadFx.doneData, (cache, tollRoad) =>
  updateCache(cache, [tollRoad]),
)

export const $tollRoadError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestTollRoadFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useTollRoadError = (id: UniqueId) =>
  useStoreMap($tollRoadError, (errors) => errors[id])
