import { z } from 'zod'
import { CompanyFetchOption } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { numberScheme, uuidOptionSchema } from '~/shared/lib/schemas'

export const formSchema = z.object({
  companyOption: uuidOptionSchema,
  companyBankDetailOption: uuidOptionSchema,
  startNumber: numberScheme.min(1, 'Обязательное поле'),
})
export type SchemaType = z.infer<typeof formSchema>

export type FormValues = Omit<
  SchemaType,
  'companyOption' | 'companyBankDetailOption'
> & {
  companyOption: CompanyFetchOption
  companyBankDetailOption?: Option | UniqueId
}
