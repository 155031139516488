import { Box, styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const MarkerLine = styled('div')`
  width: 2px;
  border-left: 1px dashed ${myPalette.green['400']};
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 1px;
  opacity: 0;
  height: calc(100% - 2px);
`

export const Mark = styled(Box)`
  width: 3px;
  height: 34px;
  z-index: 10;
  background: ${myPalette.gray['400']};
  position: absolute;
  right: 0;
  cursor: col-resize;
  transition: 0.3s ease;
  border-radius: 10px;
  top: 12px;
  opacity: 0;

  &:hover {
    box-shadow: 0 0 20px 3px rgba(34, 60, 80, 0.28);
    height: 60px;
    top: 0;
    opacity: 1;
  }
`
