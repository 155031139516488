import {
  QueryParamConfigMap,
  StringParam,
  ObjectParam,
  BooleanParam,
  useQueryParams,
} from 'use-query-params'

import { decodeObjectParam } from '~/shared/lib/utils'
import { Filter, FilterType } from './types'

const mapQueryParamTypeToFilterType: Record<
  FilterType,
  typeof StringParam | typeof ObjectParam | typeof BooleanParam
> = {
  text: StringParam,
  price: StringParam,
  boolean: BooleanParam,
  date: StringParam,
  dateRange: StringParam,
  select: {
    ...ObjectParam,
    decode: decodeObjectParam,
  },
  autocomplete: {
    ...ObjectParam,
    decode: decodeObjectParam,
  },
}

export function useFiltersQueryParams(filters: Filter[]) {
  const queryParamConfigMap: QueryParamConfigMap = Object.fromEntries(
    filters.flatMap((filter) =>
      filter.type === 'dateRange'
        ? [
            [filter.startField, mapQueryParamTypeToFilterType[filter.type]],
            [filter.endField, mapQueryParamTypeToFilterType[filter.type]],
          ]
        : [[filter.field, mapQueryParamTypeToFilterType[filter.type]]],
    ),
  )
  return useQueryParams(queryParamConfigMap)
}
