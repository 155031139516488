import { Company } from '~/shared/api'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    label: 'Дата проезда',
    type: 'dateRange',
    startField: 'eventDatetimeGte',
    endField: 'eventDatetimeLte',
  },
  {
    field: 'documentNumber',
    label: 'Номер',
    type: 'text',
  },
  {
    field: 'carPlateNumber',
    label: 'Автомобиль (гос.номер)',
    type: 'text',
  },
  {
    field: 'paymentOrgInn',
    label: 'Организация',
    type: 'autocomplete',
    fetchOptions: Company.fetchOptionsWithInn,
  },
  {
    field: 'tmsSubdivisionName',
    label: 'Подразделение авто',
    type: 'text',
  },
  {
    field: 'paymentIsPaid',
    label: 'Оплачено',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
  {
    label: 'Дата загрузки в систему',
    type: 'dateRange',
    startField: 'createdAtGte',
    endField: 'createdAtLte',
  },
  {
    field: 'tmsDriverIsNotNull',
    label: 'Водитель установлен',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
]
