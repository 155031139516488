import { Skeleton, Typography } from '@mui/joy'
import { ReactNode } from 'react'

type PageTitleProps = {
  children: ReactNode | string
  loading?: boolean
}
export function PageTitle({ children, loading }: PageTitleProps) {
  return (
    <>
      {loading ? (
        <Skeleton
          sx={{
            width: '640px',
            height: '54px',
            position: 'relative',
            borderRadius: '12px',
          }}
        />
      ) : (
        <Typography
          data-testid='page-title'
          sx={{
            fontSize: '36px',
            fontWeight: 600,
          }}
        >
          {children}
        </Typography>
      )}
    </>
  )
}
