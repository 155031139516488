import React from 'react'

import { ListUnloadTollRoads } from '~/features/UnloadTollRoads/ListUnloadTollRoads'
import { Container, PageContainer } from '~/shared/ui/Layout'

export function UnloadTollRoadsPage() {
  return (
    <PageContainer title='Выгрузка в банк-клиент'>
      <Container>
        <ListUnloadTollRoads />
      </Container>
    </PageContainer>
  )
}
