type CloseIconProps = {
  color?: string
}

export function CloseIcon({ color }: CloseIconProps) {
  return (
    <svg
      width='13'
      height='12'
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.5 1L1.5 11M1.5 1L11.5 11'
        stroke={color || 'white'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
