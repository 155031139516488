import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack } from '@mui/joy'
import { useStore } from 'effector-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { FormProvider, TextInput } from '~/shared/ui/Form'
import { EyeOffIcon } from '~/shared/ui/Icons'
import { schema, FormValues } from './lib'
import { formSubmitted, getUserMeFx, loginFx } from './model'

export function LoginForm() {
  const isPendingLogin = useStore(loginFx.pending)
  const isPendingMe = useStore(getUserMeFx.pending)

  const [typePassword, setTypePassword] = useState<'password' | 'text'>(
    'password',
  )

  const togglePasswordVisibility = () => {
    setTypePassword((prev) => (prev === 'password' ? 'text' : 'password'))
  }

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
  })

  return (
    <FormProvider
      form={form}
      onSuccess={formSubmitted}
      errorMessage='Пожалуйста, заполните все обязательные поля для авторизации'
    >
      <Stack spacing={2.5}>
        <TextInput
          placeholder='Введите почту'
          label='Почта'
          name='email'
          type='email'
        />

        <TextInput
          placeholder='Введите пароль'
          label='Пароль'
          name='password'
          type={typePassword}
          sx={{
            '.MuiInput-endDecorator': {
              margin: 0,
            },
          }}
          endDecorator={
            <IconButton onClick={togglePasswordVisibility}>
              <EyeOffIcon />
            </IconButton>
          }
        />

        <Box>
          <Button
            sx={{ mt: '40px' }}
            type='submit'
            fullWidth
            loading={isPendingLogin || isPendingMe}
          >
            Войти
          </Button>
        </Box>
      </Stack>
    </FormProvider>
  )
}
