import { Grid } from '@mui/joy'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { FormTitle, MaskInput, TextInput, FormProvider } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { FormValues } from '../model'

type Props = {
  defaultValues?: Partial<FormValues>
  isLoading: boolean
}

export function Form({ defaultValues, isLoading }: Props) {
  const form = useForm<FormValues>({ defaultValues })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues])

  return (
    <FormProvider form={form}>
      <Container>
        <FormTitle>Информация</FormTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='ФИО'
              name='name'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>
          <Grid xs={6}>
            <MaskInput
              label='Телефон'
              name='phone'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>
          <Grid xs={6}>
            <TextInput
              label='E-mail'
              name='email'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>
          <Grid xs={6}>
            <TextInput
              label='Telegram ID'
              name='telegramUserId'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  )
}
