import WarningAmberSharpIcon from '@mui/icons-material/WarningAmberSharp'
import { Typography } from '@mui/joy'
import { Button } from '~/shared/ui/Buttons'
import { Container } from '~/shared/ui/Layout'

type Props = {
  message?: string
  fullScreen?: boolean
}

export function NotFound({ message = 'Страница не найдена' }: Props) {
  return (
    <Container
      alignItems='center'
      spacing={3}
      sx={{ flex: 1, justifyContent: 'center' }}
    >
      <WarningAmberSharpIcon color='warning' sx={{ fontSize: 60 }} />
      <Typography level='h1' fontSize={20}>
        {message}
      </Typography>
      <Button to='/'>На главную</Button>
    </Container>
  )
}
