import { Snackbar } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { Button } from '~/shared/ui/Buttons'
import { CloseIcon, SaveIcon } from '~/shared/ui/Icons'
import { Container } from '~/shared/ui/Layout'
import { $isEditing, editingEnded, Gate } from './model'

type FormButtonsType = {
  loading?: boolean
  isCreating?: boolean
  disabled?: boolean
}

export const FormButtons = ({
  isCreating,
  loading,
  disabled,
}: FormButtonsType) => {
  const isEditing = useStore($isEditing)
  useGate(Gate)

  const SaveButton = useMemo(
    () => (
      <Button
        variant='green'
        startDecorator={<SaveIcon />}
        type='submit'
        data-testid='form-submit-button'
        disabled={disabled}
        sx={{
          padding: '12px 24px',
          marginRight: '12px',
          width: '182px',
        }}
        loading={loading}
      >
        {isCreating ? 'Создать' : 'Сохранить'}
      </Button>
    ),
    [disabled, isCreating, loading],
  )

  if (isCreating) return <Container>{SaveButton}</Container>

  return (
    <Snackbar
      open={isEditing}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        background: 'none',
        border: 'none',
        boxShadow: 'none',
        zIndex: 1100,
      }}
    >
      {SaveButton}

      <Button
        variant='red'
        startDecorator={<CloseIcon />}
        onClick={() => editingEnded()}
        disabled={loading}
        sx={{
          padding: '12px 24px',
          width: '182px',
        }}
        data-testid='form-cancel-button'
      >
        Отменить
      </Button>
    </Snackbar>
  )
}
