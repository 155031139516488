import * as z from 'zod'
import { Role } from '~/shared/api'
import { ApiModel, ToManyRelation } from '~/shared/api/core'
import { Option } from '~/shared/config/constants'
import { dateSchema, uuidOptionsSchema } from '~/shared/lib/schemas'
import { isString } from '~/shared/lib/utils'

const attributeSchema = z.object({
  email: z
    .string()
    .min(1, 'Обязательное поле')
    .email('Неверный адрес электронной почты'),
  name: z.string().trim().min(1, 'Обязательное поле'),
})
const relationsSchema = z.object({
  rolesIds: uuidOptionsSchema.min(1, 'Обязательное поле'),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type EmployeeAttributes = z.infer<typeof attributeSchema>

export class Employee extends ApiModel<typeof schema, EmployeeAttributes> {
  static jsonApiType = 'users'
  static schema = schema

  getEmail(): string {
    return this.getAttribute('email')
  }
  getName(): string {
    return this.getAttribute('name')
  }

  roles(): ToManyRelation<Role, this> {
    return this.hasMany(Role)
  }
  getRoles(): Role[] {
    return this.getRelation('roles') ?? []
  }
  setRoles(ids: UniqueId[] | Option[]) {
    let roles: Role[] = []
    ids.map((roleId) => {
      const role = new Role()
      if (isString(roleId)) {
        role.setApiId(roleId)
        roles = roles.concat(role)
      }
    })
    this.setRelation('roles', roles)
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }
  static async fetchOptions(search: string) {
    const response = await Employee.where('name', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
