import { z } from 'zod'
import { Company } from '~/shared/api'

export const formSchema = Company.schema.pick({
  name: true,
  inn: true,
  ogrn: true,
  kpp: true,
  payment: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema
