import { Accordion, AccordionDetails } from '@mui/joy'
import { GridRow, GridRowProps } from '@mui/x-data-grid'
import { ChangedFieldsList } from '../ChangedFieldsList'
import { GridRow as GridRowType } from './types'

type RowProps = GridRowProps & {
  openedRows: GridRowType[]
}

export function Row(props: RowProps) {
  const { openedRows, ...params } = props
  const currentRow = openedRows.find(
    (openedRow) => openedRow.id === params.rowId,
  )

  return (
    <Accordion expanded={Boolean(currentRow)} sx={{ padding: 0 }}>
      <GridRow {...params} />

      <AccordionDetails
        sx={{
          padding: '0 40px',
        }}
      >
        <ChangedFieldsList changeFields={currentRow?.changes} />
      </AccordionDetails>
    </Accordion>
  )
}
