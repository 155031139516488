import { useStore } from 'effector-react'
import { useMemo } from 'react'
import { Form } from '~/entities/Company'
import { formSubmitted, createFx } from './model'

export const AddCompany = () => {
  const isSubmitting = useStore(createFx.pending)

  const defaultValues = useMemo(
    () => ({
      payment: true,
    }),
    [],
  )
  return (
    <Form
      isSubmitting={isSubmitting}
      isCreating
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
    />
  )
}
