import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'

export const Gate = createGate<{ aggregatorId: UniqueId }>()
export const domain = createDomain('feature.button-editing')

export const editingStarted = domain.createEvent()
export const editingEnded = domain.createEvent()
export const $isEditing = domain
  .createStore<boolean>(false)
  .on(editingStarted, () => true)
  .on(editingEnded, () => false)

sample({
  clock: Gate.close,
  target: editingEnded,
})
