import { Stack, StackProps } from '@mui/joy'
import { ReactNode } from 'react'
import { myPalette } from '~/shared/lib/theme'

type ContainerProps = {
  children: ReactNode
  isHeader?: boolean
} & StackProps

export function Container({
  children,
  sx,
  isHeader = false,
  ...props
}: ContainerProps) {
  return (
    <Stack
      sx={{
        background: myPalette.white.main,
        padding: '24px 32px',
        borderRadius: '32px',
        marginBottom: isHeader ? '32px' : '16px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Stack>
  )
}
