import { Box } from '@mui/joy'
import { forwardRef, HTMLAttributes } from 'react'
import { Button } from '~/shared/ui/Buttons'
import { Wrapper } from './styled'

export const Listbox = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLElement> & {
    params: {
      onClick: () => void
      isSelectAll?: boolean
    }
  }
>(({ params: { isSelectAll, onClick }, ...props }, ref) => {
  const children = (props.children || []) as React.ReactNode[][]
  const isOptions = Boolean(children[0]?.length)
  return (
    <>
      <Wrapper ref={ref} {...props}>
        {isOptions && isSelectAll && (
          <Button variant='textBrand' onClick={onClick}>
            Выбрать все
          </Button>
        )}
        <Box
          sx={{
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Wrapper>
    </>
  )
})
Listbox.displayName = 'Listbox'
