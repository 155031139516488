import { useCallback, useState } from 'react'
import { openBlobFile } from '~/shared/lib/file/openBlobFile'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Button } from '~/shared/ui/Buttons'
import { FileIcon } from '~/shared/ui/Icons'

type FileExportBlobProps = {
  fn: () => Promise<Blob> | void | Blob
  title: string
  target?: '_self' | '_blank' | '_parent' | '_top'
}

export function FileExportBlob({
  fn,
  title,
  target = '_self',
}: FileExportBlobProps) {
  const [isLoading, toggleIsLoading] = useState(false)

  const handlePrintFile = useCallback(async () => {
    try {
      toggleIsLoading(true)
      const response = await fn()

      if (!response) return

      openBlobFile(response, target)
    } catch (e) {
      logger.error(e)
      snackbarEnqueued({
        message: 'Ошибка получения файла',
        variant: 'error' as const,
      })
    } finally {
      toggleIsLoading(false)
    }
  }, [fn, target])

  return (
    <Button
      startDecorator={<FileIcon />}
      onClick={handlePrintFile}
      loading={isLoading}
      variant='gray'
    >
      {title}
    </Button>
  )
}
