import { GridColDef } from '@mui/x-data-grid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Поле',
    field: 'field',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Старое значение',
    field: 'old',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Новое значение',
    field: 'new',
    sortable: false,
    flex: 1,
  },
]
