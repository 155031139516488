import { useCallback, useState } from 'react'
import { storageGet } from '~/shared/lib/storage'
import { mapColumnSizesToColumns } from './ResizableHeader/helpers'
import { ColumnSize } from './ResizableHeader/type'
import { ColumnSetting, mapSettingsToColumns } from './SettingsColumns'
import { Columns } from './types'

const getStorageKeys = (key: string) => ({
  storageKeyResize: `columns-resizable-${key}`,
  storageKeySettings: `columns-settings-${key}`,
})

export const useColumns = (gridKey: string, defaultColumns: Columns) => {
  const mountedColumns = useCallback(() => {
    const columnSettings = storageGet<ColumnSetting[]>(
      getStorageKeys(gridKey).storageKeySettings,
    )
    const columnSizes = storageGet<ColumnSize[]>(
      getStorageKeys(gridKey).storageKeyResize,
    )
    const mappedSettingsColumns = mapSettingsToColumns(
      columnSettings,
      defaultColumns,
    )
    return mapColumnSizesToColumns(columnSizes, mappedSettingsColumns)
  }, [defaultColumns, gridKey])

  const [columns, setColumns] = useState<Columns>(mountedColumns())

  const updateColumns = useCallback((columns: Columns) => {
    setColumns(columns)
  }, [])

  return {
    storageKeyResize: getStorageKeys(gridKey).storageKeyResize,
    storageKeySettings: getStorageKeys(gridKey).storageKeySettings,
    columns,
    updateColumns,
  }
}
