import { Grid } from '@mui/joy'
import { useState } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/styles.css'
import { Button } from '~/shared/ui/Buttons'
import { ButtonProps } from '~/shared/ui/Buttons/Button'
import { GalleryImage } from './type'

type ImageSliderProps = {
  images: GalleryImage[]
  xs?: number
  showList?: boolean
  buttonTitle?: string
  buttonProps?: Partial<ButtonProps>
  isLoading?: boolean
}

export function Gallery({
  images,
  xs = 3,
  showList = true,
  buttonTitle,
  buttonProps,
  isLoading,
}: ImageSliderProps) {
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const changeSelectedIndex = (index: number) => setSelectedIndex(index)

  return (
    <>
      {showList && (
        <Grid container spacing={2.5} alignItems='center'>
          {images.map((item, index) => (
            <Grid
              xs={xs}
              sx={{
                cursor: 'pointer',
                transition: '0.15s ease opacity',

                '&:hover': {
                  opacity: '0.5',
                },
              }}
              key={item.id}
              onClick={() => changeSelectedIndex(index)}
            >
              <img
                src={item.preview}
                width='100%'
                height='auto'
                alt=''
                loading='lazy'
              />
            </Grid>
          ))}
        </Grid>
      )}
      {buttonTitle && (
        <Button
          variant='gray'
          {...buttonProps}
          onClick={() => changeSelectedIndex(0)}
          loading={isLoading}
        >
          {buttonTitle}
        </Button>
      )}
      <Lightbox
        carousel={{
          finite: images.length === 1,
        }}
        slides={images}
        open={selectedIndex >= 0}
        index={selectedIndex}
        close={() => changeSelectedIndex(-1)}
        plugins={[Zoom]}
        animation={{ zoom: 300 }}
        zoom={{ maxZoomPixelRatio: 10, scrollToZoom: true }}
      />
    </>
  )
}
