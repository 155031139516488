import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ViewPenalty } from '~/features/Penalty/ViewPenalty'
import { penaltyModel } from '~/entities/Penalty'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { PageContainer, Container, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Осн. информация', anchor: 'main' },
  { label: 'Нарушение', anchor: 'violation' },
  { label: 'Фото', anchor: 'photos' },
  { label: 'Оплата', anchor: 'payment' },
  { label: 'Получатель платежа', anchor: 'paymentReceiver' },
  { label: 'Выгрузка', anchor: 'unloadingData' },
]

export function ViewPenaltyPage() {
  const params = useParams<{ penaltyId: string }>()
  const penaltyId = params.penaltyId as string

  useGate(penaltyModel.Gate, { penaltyId })

  const penalty = useStore(penaltyModel.$penalty)
  const isLoading = useStore(penaltyModel.requestPenaltyFx.pending)

  const protocolNumber = penalty?.getProtocolNumber()
  const protocolDate = penalty?.getProtocolDate()

  const title = `Штраф [${protocolNumber}] от ${
    protocolDate ? formatDateTimeForUI(protocolDate) : ''
  }`

  const penaltyError = penaltyModel.usePenaltyError(penaltyId)

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [{ title: 'Штрафы', link: '/penalties' }, { title: title }],
    [title],
  )

  if (penaltyError) {
    return <NotFound message='Не удалось загрузить штраф' />
  }

  return (
    <PageContainer title={title}>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{title}</PageTitle>
      </Container>

      <ViewPenalty />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
