import { createDomain, sample } from 'effector'
import { companyModel } from '~/entities/Company'
import { companyBankDetailFormModel } from '~/entities/CompanyBankDetail'
import { AxiosErrorType, CompanyBankDetail } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
const domain = createDomain('feature.companies.companyBankDetail')

export const formSubmittedCreate = domain.createEvent<{
  formValues: companyBankDetailFormModel.FormValues
  fn: () => void
}>()

export const createFx = domain.createEffect<
  {
    companyId: UniqueId
    formValues: companyBankDetailFormModel.FormValues
    fn: () => void
  },
  CompanyBankDetail,
  AxiosErrorType
>({
  async handler({ companyId, formValues, fn }) {
    const { bankclientMaxDocument, ...attr } = formValues

    const companyBankDetail = new CompanyBankDetail({
      ...attr,
      bankclientMaxDocument: bankclientMaxDocument as number,
    })

    companyBankDetail.setCompany(companyId)

    const result = await companyBankDetail.save()
    const resCompanyBandDetail = result.getModel() as CompanyBankDetail
    fn()
    return resCompanyBandDetail
  },
})

sample({
  clock: formSubmittedCreate,
  source: companyModel.$companyId,
  filter: isString,
  fn: (companyId, entity) => ({ companyId, ...entity }),
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Реквизиты добавлены',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
