import { Box } from '@mui/joy'
import { Link } from 'react-scroll'
import { Wrapper } from './styled'

export type QuickNavigationOptions = {
  label: string
  anchor: string
}[]

type QuickNavigationProps = {
  options: QuickNavigationOptions
}
export function QuickNavigation({ options }: QuickNavigationProps) {
  return (
    <>
      <Box sx={{ marginBottom: '88px' }} />
      <Wrapper direction='row' spacing={1}>
        {options.map(({ label, anchor }) => (
          <Link
            key={anchor}
            to={anchor}
            smooth={true}
            spy
            duration={500}
            offset={-110}
          >
            {label}
          </Link>
        ))}
      </Wrapper>
    </>
  )
}
