import { z } from 'zod'
import { Permission } from '~/shared/api'
import { ToManyRelation } from '~/shared/api/core'
import { Option } from '~/shared/config/constants'
import { dateSchema, uuidOptionsSchema } from '~/shared/lib/schemas'
import { isString } from '~/shared/lib/utils'
import { ApiModel } from './core'

const attributeSchema = z.object({
  name: z.string().min(1),
})

const relationsSchema = z.object({
  permissionsIds: uuidOptionsSchema.optional(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

const parseNames = [{ key: 'Super Admin', label: 'Администратор' }]

export type RoleAttributes = z.infer<typeof schema>

export class Role extends ApiModel<typeof schema> {
  static jsonApiType = 'roles'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  getParseName(): string {
    return (
      parseNames.find((item) => item.key === this.getAttribute('name'))
        ?.label ?? this.getAttribute('name')
    )
  }

  permissions(): ToManyRelation<Permission, this> {
    return this.hasMany(Permission)
  }
  getPermissions(): Permission[] {
    return this.getRelation('permissions') ?? []
  }
  setPermissions(ids: UniqueId[] | Option[]) {
    let permissions: Permission[] = []
    ids.map((permissionId) => {
      const permission = new Permission()
      if (isString(permissionId)) {
        permission.setApiId(permissionId)
        permissions = permissions.concat(permission)
      }
    })
    this.setRelation('permissions', permissions)
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getParseName(),
    }
  }

  static async fetchOptions() {
    const response = await Role.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
