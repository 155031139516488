import { z } from 'zod'

import { User } from '~/shared/api/user'
import { EventAuditEnum } from '~/shared/config/enums'
import { ApiModel, ToOneRelation } from './core'

const schema = z.object({
  event: z.nativeEnum(EventAuditEnum),
  changes: z.record(
    z.string(),
    z.object({
      old: z.string().nullable(),
      new: z.string().nullable(),
    }),
  ),
  createdAt: z.string(),
})

export type AuditAttributes = z.infer<typeof schema>

export class Audit extends ApiModel<typeof schema, AuditAttributes> {
  static jsonApiType = 'audits'

  static schema = schema

  getChanges(): AuditAttributes['changes'] {
    return this.getAttribute('changes')
  }

  getEvent(): EventAuditEnum {
    return this.getAttribute('event')
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  user(): ToOneRelation<User, this> {
    return this.hasOne(User)
  }

  getUser(): User {
    return this.getRelation('user')
  }
}
