import { ToOneRelation } from 'coloquent'
import { z } from 'zod'
import { Company } from '~/shared/api/company'
import {
  dateSchema,
  accountNumberRequiredSchema,
  bikRequiredSchema,
  uuidOptionsSchema,
  numberRequiredWithZeroScheme,
  corrAccountNumberRequiredSchema,
} from '~/shared/lib/schemas'
import { ApiModel } from './core'

export const attributeSchema = z.object({
  title: z.string().trim().min(1, 'Обязательное поле'),
  bankName: z.string().trim().min(1, 'Обязательное поле'),
  accountNumber: accountNumberRequiredSchema,
  corrAccountNumber: corrAccountNumberRequiredSchema,
  bik: bikRequiredSchema,
  bankclientMaxDocument: numberRequiredWithZeroScheme,
})

const relationsSchema = z.object({
  companyId: uuidOptionsSchema.min(1, 'Обязательное поле'),
})

export const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type CompanyBankDetailAttributes = z.infer<typeof attributeSchema>

export class CompanyBankDetail extends ApiModel<
  typeof schema,
  CompanyBankDetailAttributes
> {
  static jsonApiType = 'company-bank-details'

  static schema = schema

  getName(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  company(): ToOneRelation<Company, this> {
    return this.hasOne(Company)
  }
  getCompany(): Company {
    return this.getRelation('company')
  }
  setCompany(companyId: UniqueId) {
    const company = new Company()
    company.setApiId(companyId)
    this.setRelation('company', company)
  }

  static async fetchOptions(search: string, companyId?: string) {
    const response = await CompanyBankDetail.where('title', search)
      .where('companyId', companyId || '')
      .get(1)
    return response.getData().map((o) => o.getOption())
  }
}
