import { createDomain, sample } from 'effector'
import { companyModel } from '~/entities/Company'
import { companyBankDetailFormModel } from '~/entities/CompanyBankDetail'
import { AxiosErrorType, CompanyBankDetail } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { getIdFromUrl, isString } from '~/shared/lib/utils'
import { addCompanyBankDetailModel } from '../AddCompanyBankDetail'

const domain = createDomain('feature.companies.companyBankDetail')

export const updateFx = domain.createEffect<
  {
    params: CompanyBankDetailEditFormModel
    companyId: UniqueId
  },
  CompanyBankDetail,
  AxiosErrorType
>({
  async handler({ params, companyId }) {
    const { companyBankDetailId, bankclientMaxDocument, fn, ...attr } = params
    const companyBankDetail = new CompanyBankDetail(
      { ...attr, bankclientMaxDocument: bankclientMaxDocument as number },
      companyBankDetailId as string,
    )

    companyBankDetail.setCompany(companyId)

    const response = await companyBankDetail.save()
    fn()
    return response.getModel() as CompanyBankDetail
  },
})

// Вывод реквизитов организаций списком
export const fetchCompanyBankDetailsFx = domain.createEffect<
  UniqueId,
  CompanyBankDetail[]
>({
  async handler(companyId) {
    const builder = CompanyBankDetail.with('company')
      .where('companyId', companyId)
      .limit(100)
      .orderBy('-createdAt')

    const response = await builder.get(1)
    return response.getData()
  },
})

export const $companyBankDetailList = domain
  .createStore<CompanyBankDetail[]>([])
  .on(
    fetchCompanyBankDetailsFx.doneData,
    (_, companyBankDetails) => companyBankDetails,
  )
  .on(
    addCompanyBankDetailModel.createFx.doneData,
    (state, companyBankDetail) => [companyBankDetail, ...state],
  )
  .on(updateFx.doneData, (state, companyBankDetail) =>
    state.map((stateCompanyBankDetail) =>
      stateCompanyBankDetail.getApiId() === companyBankDetail.getApiId()
        ? companyBankDetail
        : stateCompanyBankDetail,
    ),
  )

sample({
  clock: companyModel.$companyId,
  filter: isString,
  target: fetchCompanyBankDetailsFx,
})

// Обновление существующих банковских реквизитов
type CompanyBankDetailEditFormModel = companyBankDetailFormModel.FormValues & {
  companyId?: { companyId: UniqueId }
  companyBankDetailId?: UniqueId
  fn: () => void
}
export const formSubmittedUpdate =
  domain.createEvent<CompanyBankDetailEditFormModel>()

sample({
  clock: formSubmittedUpdate,
  source: companyModel.$companyId,
  filter: isString,
  fn: (companyId, params) => {
    return {
      params,
      companyId,
    }
  },
  target: updateFx,
})
sample({
  clock: updateFx.doneData,
  fn() {
    return {
      message: 'Банковские реквизиты отредактированы',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

const startLoading = domain.createEvent<UniqueId>()
const endLoading = domain.createEvent<UniqueId>()

sample({
  clock: updateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $loadingsIds = domain
  .createStore<UniqueId[]>([])
  .on(startLoading, (state, id) => [...state, id])
  .on(endLoading, (state, id) =>
    state.filter((stateId) => String(stateId) !== String(id)),
  )

sample({
  clock: formSubmittedUpdate,
  fn: (formValues) => {
    return String(formValues.companyBankDetailId) // TODO разобраться с фильтром
  },
  target: startLoading,
})
sample({
  clock: updateFx.doneData,
  fn: (CompanyBankDetail) => {
    return CompanyBankDetail.getApiId() as string
  },
  target: endLoading,
})
sample({
  clock: updateFx.failData,
  fn(e) {
    return getIdFromUrl(String(e.config.url))
  },
  target: endLoading,
})
