import { styled, Menu as JoyMenu, MenuItem as JoyMenuItem, Box } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Menu = styled(JoyMenu)`
  padding: 8px;
  min-width: 276px;
  transform: none !important;
  top: 54px !important;
  border-radius: 16px;
  border: 1px solid ${myPalette.gray['300']} !important;
`

export const MenuItem = styled(JoyMenuItem)`
  padding: 0;

  &:hover {
    background-color: transparent !important;
  }
`

export const DraggableItemWrapper = styled(Box)`
  border-radius: 12px;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: background 0.2s ease;

  &:hover {
    background: ${myPalette.gray['50']};
  }
`

export const DraggableIcon = styled(Box)`
  display: flex;
  margin-right: 8px;
  cursor: grab;
`
