import { useCallback } from 'react'

import { usePermissions } from '~/entities/viewer'
import { Employee, Role } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'

import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListEmployees = () => {
  const { canUsersUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      let builder = Employee.limit(pageSize).with('roles').orderBy('-createdAt')

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)

      const data = response.getData()
      const rows = data.map((employee: Employee) => ({
        ...employee.getAttributes(),
        rolesIds: employee.getRoles()?.map((item: Role) => item.getParseName()),
        id: employee.getApiId() as string,
      }))

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='users'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      smallTitle='Сотрудники'
      headerExtra={
        <Button startDecorator={<PlusIcon />} to='new' show={canUsersUpdate}>
          Добавить
        </Button>
      }
    />
  )
}
