import {
  MenuButton as JoyMenuButton,
  styled,
  Menu as JoyMenu,
  MenuItem as JoyMenuItem,
} from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const MenuButton = styled(JoyMenuButton)`
  padding: 0 12px;
  background: ${myPalette.white.main};
  border-radius: 30px;
  border: none;
  transition: 0.3s ease !important;
`

export const Menu = styled(JoyMenu)`
  background: ${myPalette.white.main};
  padding: 12px;
  min-width: 200px;
  border-radius: 30px;
  border: none;
`

export const MenuItem = styled(JoyMenuItem)`
  height: 45px;
  border-radius: 30px;

  &:hover {
    background-color: ${myPalette.gray['50']} !important;
  }
` as typeof JoyMenuItem
