import { Box } from '@mui/joy'
import { Theme } from '@mui/joy'
import { SxProps } from '@mui/system'
import { ReactNode, useCallback, useEffect } from 'react'
import { useMemo } from 'react'
import { matchPath, Route, Routes, useLocation } from 'react-router-dom'
import { history } from '~/shared/lib/history'
import { cutStringBefore } from '~/shared/lib/utils'
import { Tabs, Tab, TabList } from './styled'
import { Tabs as TabsType, Tab as TabType } from './type'

type RoutesTabsProps = {
  config: TabsType
  parentPath: string
  routerBoxSx?: SxProps<Theme>
  parentContent?: ReactNode
}
export function RoutesTabs({
  config,
  parentPath,
  routerBoxSx,
  parentContent,
}: RoutesTabsProps) {
  const { pathname } = useLocation()

  const selectedParent = useMemo(() => {
    const currentPath = cutStringBefore(pathname, parentPath)
    return config.find((conf) => currentPath.startsWith(conf.path))
  }, [config, parentPath, pathname])

  const selectedChildren = useMemo(
    () =>
      selectedParent?.children?.find((child) =>
        matchPath(
          `${parentPath}${selectedParent?.path}${child.path}`,
          pathname,
        ),
      ),
    [parentPath, pathname, selectedParent?.children, selectedParent?.path],
  )

  const handleChange = (path: TabType['path']) => {
    handleReplace(path)
  }

  const handleReplace = useCallback(
    (path: TabType['path']) => {
      history.replace(`${parentPath}${path}`)
    },
    [parentPath],
  )

  useEffect(() => {
    if (!selectedParent) {
      handleReplace(config[0]?.path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParent])

  if (!selectedParent) return null

  return (
    <>
      {!selectedChildren?.full && <>{parentContent}</>}

      <Tabs
        value={selectedParent?.path}
        sx={{ display: selectedChildren?.full ? 'none' : 'block' }}
      >
        <TabList disableUnderline>
          {config.map((tab) => (
            <Box
              key={tab.path}
              onClick={() => handleChange(tab.path)}
              sx={{ width: '100%' }}
            >
              <Tab
                disableIndicator
                value={tab.path}
                data-testid={`tab-${tab.label}`}
              >
                {tab.label}
              </Tab>
            </Box>
          ))}
        </TabList>
      </Tabs>

      <Box sx={{ ...routerBoxSx }}>
        <Routes>
          {config.map((tab) => (
            <Route key={tab.path}>
              <Route path={`${tab.path}/*`} element={tab.content} />
              {(tab?.children || []).map((child) => (
                <Route
                  key={`${tab.path}${child.path}`}
                  path={`${tab.path}${child.path}`}
                  element={child.content}
                />
              ))}
            </Route>
          ))}
        </Routes>
      </Box>
    </>
  )
}

export default RoutesTabs
