import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { PenaltyAttributes, ThumbType } from '~/shared/api'
import { FormProvider } from '~/shared/ui/Form'
import { Main } from './Main'
import { Payment } from './Payment'
import { PaymentReceiver } from './PaymentReceiver'
import { Photos } from './Photos'
import { UnloadingData } from './UnloadingData'
import { Violation } from './Violation'

type FormValues = PenaltyAttributes & {
  photos: ThumbType[]
}

type FormProps = {
  defaultValues?: Partial<FormValues & { carBrandModel: string }>
  isLoading: boolean
}

export function Form({ defaultValues, isLoading }: FormProps) {
  const form = useForm<FormValues>({
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  const files = useMemo(
    () => defaultValues?.files || [],
    [defaultValues?.files],
  )

  return (
    <FormProvider form={form}>
      <Element name='main'>
        <Main loading={isLoading} />
      </Element>
      <Element name='violation'>
        <Violation loading={isLoading} />
      </Element>
      <Element name='photos'>
        <Photos loading={isLoading} photos={files} />
      </Element>
      <Element name='payment'>
        <Payment loading={isLoading} />
      </Element>
      <Element name='paymentReceiver'>
        <PaymentReceiver loading={isLoading} />
      </Element>
      <Element name='unloadingData'>
        <UnloadingData loading={isLoading} />
      </Element>
    </FormProvider>
  )
}
