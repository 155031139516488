import { z } from 'zod'
import { CompanyBankDetail } from '~/shared/api'

export const formSchema = CompanyBankDetail.schema.pick({
  title: true,
  accountNumber: true,
  corrAccountNumber: true,
  bik: true,
  bankName: true,
  bankclientMaxDocument: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<TypeFormSchema, 'bankclientMaxDocument'> & {
  bankclientMaxDocument: number | string
}
