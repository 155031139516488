import { useCallback, useState } from 'react'

export const useModal = () => {
  const [isModalShow, toggleIsModalShow] = useState(false)

  const openModal = useCallback(() => {
    toggleIsModalShow(true)
  }, [])

  const closeModal = useCallback(() => {
    toggleIsModalShow(false)
  }, [])

  return {
    isModalShow,
    openModal,
    closeModal,
  }
}
