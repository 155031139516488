import { Box, Skeleton, Typography } from '@mui/joy'
import React, { useMemo } from 'react'
import { ThumbType } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { FormTitle } from '~/shared/ui/Form'
import { Gallery, mapPhotoToImage } from '~/shared/ui/Gallery'
import { Container } from '~/shared/ui/Layout'

type MainProps = {
  loading: boolean
  photos: ThumbType[]
}

export function Photos({ loading, photos }: MainProps) {
  const mappedPhotos = useMemo(() => photos.map(mapPhotoToImage), [photos])

  return (
    <Container sx={{ minHeight: '220px' }}>
      <FormTitle>Фото</FormTitle>
      {loading && (
        <Box sx={{ position: 'relative', flex: 1 }}>
          <Skeleton sx={{ borderRadius: '8px' }} />
        </Box>
      )}

      {!!photos?.length && <Gallery images={mappedPhotos} />}

      {!loading && !photos?.length && (
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            color: myPalette.gray['600'],
          }}
        >
          <Typography>Нет данных</Typography>
        </Box>
      )}
    </Container>
  )
}
