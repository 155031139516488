import { GridColDef } from '@mui/x-data-grid'
import { priceFormat } from '~/shared/lib/utils'
import { ColumnDate } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата протокола',
    field: 'protocolDate',
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
    flex: 1,
  },
  {
    headerName: 'Номер протокола',
    field: 'protocolNumber',
    flex: 1,
  },
  {
    headerName: 'Сумма к оплате',
    field: 'paymentAmount',
    flex: 1,
    renderCell: ({ value }) => value && priceFormat(value),
  },

  {
    headerName: 'Автомобиль (гос.номер)',
    field: 'carPlateNumber',
    flex: 1,
  },
  {
    headerName: 'Оплата со скидкой',
    field: 'paymentDiscountSize',
    renderCell: ({ value }) => (value > 0 ? 'Да' : 'Нет'),
    flex: 1,
  },
  {
    headerName: 'Организация',
    field: 'paymentOrgInn',
    flex: 1,
  },
  {
    headerName: 'Платеж выгружен',
    field: 'bankclientExported',
    flex: 1,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
]
