import { Chip as JoyChip, ChipDelete as JoyChipDelete } from '@mui/joy'
import { ChipTypeMap } from '@mui/joy/Chip/ChipProps'
import { myPalette } from '~/shared/lib/theme'

type ChipDeleteProps = ChipTypeMap['props'] & {
  onDelete: () => void
}

export function ChipDelete({
  children,
  onDelete,
  disabled,
  ...props
}: ChipDeleteProps) {
  return (
    <JoyChip
      {...props}
      sx={{
        ...props.sx,
        background: myPalette.brand['100'],
        borderRadius: '8px',
        border: 'none',
        color: myPalette.brand['600'],
        fontSize: '14px',
        height: '23px',
        padding: '4px 8px',
        fontWeight: 500,
      }}
      endDecorator={
        !disabled && (
          <JoyChipDelete
            onDelete={onDelete}
            sx={{
              background: myPalette.brand['100'],
              marginLeft: '6px',
              svg: {
                color: myPalette.brand['400'],
              },

              '&:hover': {
                background: myPalette.brand['300'],
                svg: {
                  color: myPalette.brand['600'],
                },
              },

              '&.Mui-disabled': {
                background: myPalette.brand['100'],
              },
            }}
          />
        )
      }
    >
      {children}
    </JoyChip>
  )
}
