import { Grid } from '@mui/joy'
import React from 'react'
import {
  DatePickerInput,
  FormTitle,
  TextareaInput,
  TextInput,
} from '~/shared/ui/Form'
import { CalendarIcon } from '~/shared/ui/Icons'
import { Container } from '~/shared/ui/Layout'

type MainProps = {
  loading: boolean
}

export function Main({ loading }: MainProps) {
  return (
    <Container>
      <FormTitle>Основная информация</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='Водитель'
            name='tmsDriverName'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='Автомобиль'
            name='carBrandModel'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='Гос. номер'
            name='carPlateNumber'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='Номер протокола'
            name='documentNumber'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='СТС'
            name='carSts'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <DatePickerInput
            label='Дата проезда'
            name='documentDate'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <TextInput
            label='Дата и время загрузки'
            name='createdAt'
            readOnly
            skeletonShow={loading}
            startDecorator={<CalendarIcon />}
          />
        </Grid>

        <Grid xs={6}>
          <TextareaInput
            label='Комментарий'
            name='comment'
            minRows={4}
            maxRows={4}
            readOnly
            skeletonShow={loading}
            sx={{
              height: '138px',
            }}
          />
        </Grid>
        <Grid container xs={6} spacing={2.5}>
          <Grid xs={12}>
            <TextInput
              label='Подразделение'
              name='tmsSubdivisionName'
              readOnly
              skeletonShow={loading}
            />
          </Grid>
          <Grid xs={12}>
            <TextInput
              label='Организация'
              name='paymentOrgName'
              readOnly
              skeletonShow={loading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
