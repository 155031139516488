import { Grid } from '@mui/joy'
import React from 'react'
import { FormTitle, TextInput } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type PaymentReceiverProps = {
  loading: boolean
}

export function PaymentReceiver({ loading }: PaymentReceiverProps) {
  return (
    <Container>
      <FormTitle>Данные получателя платежа</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='Получатель платежа'
            name='recipient'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='ИНН'
            name='recipientInn'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Наименование банка'
            name='recipientBankName'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='КПП'
            name='recipientKpp'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Расчетный счет'
            name='recipientBankAccountNumber'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='БИК'
            name='recipientBankBik'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Корреспондентский счет'
            name='recipientBankCorrAccountNumber'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
