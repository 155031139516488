import { GridColumnHeaderParams } from '@mui/x-data-grid'
import * as React from 'react'
import { useCallback } from 'react'
import { MIN_COLUMN_WIDTH } from '~/shared/config/constants'
import { storageSet } from '~/shared/lib/storage'
import { useGridContext } from '../context'
import { normalizeColumnWidth, setStyles } from './helpers'
import { Mark } from './styled'
import { ColumnSize } from './type'

type ResizableHeaderParams = GridColumnHeaderParams

export function ResizableHeader(params: ResizableHeaderParams) {
  const { columns, storageKeyResize, updateColumns } = useGridContext()

  const handleUpdateColumns = useCallback(
    ({ field, width }: ColumnSize) => {
      const changedColumns = columns.map((column) =>
        column.field === field
          ? { ...column, minWidth: width, maxWidth: width }
          : column,
      )
      const columnSizes = changedColumns
        .filter(({ maxWidth }) => maxWidth)
        .map(({ field, maxWidth }) => ({
          field,
          width: maxWidth,
        }))
      updateColumns(changedColumns)
      storageSet(storageKeyResize, columnSizes)
    },
    [columns, storageKeyResize, updateColumns],
  )

  const handleMouseDown = (
    mouseDownEvent: React.MouseEvent<HTMLDivElement>,
  ) => {
    mouseDownEvent.preventDefault()
    const markerLine = document.getElementById('grid-marker-line')

    if (!markerLine) {
      throw new Error('Resizable title must be inside ResizableTable component')
    }

    const markerLineLeftOffset = markerLine.getBoundingClientRect().left
    setStyles(markerLine, mouseDownEvent.clientX - markerLineLeftOffset, 1)

    const initialWidth = params.colDef.computedWidth || 0
    const startX = mouseDownEvent.clientX

    document.onmousemove = (e) => {
      const columnWidth = initialWidth + e.clientX - startX

      const isBetweenPosition = columnWidth >= MIN_COLUMN_WIDTH

      if (!isBetweenPosition) return

      setStyles(markerLine, e.clientX - markerLineLeftOffset, 1)
    }

    document.onmouseup = (e) => {
      const columnWidth = initialWidth + e.clientX - startX
      setStyles(markerLine, 0, 0)

      document.onmousemove = null
      document.onmouseup = null

      if (initialWidth === columnWidth) return

      handleUpdateColumns({
        field: params.field,
        width: normalizeColumnWidth(Math.floor(columnWidth)),
      })
    }
  }

  if (!params) return null

  return (
    <div className='custom-header' aria-label={params.colDef.headerName}>
      {params.colDef.headerName}
      <Mark onMouseDown={handleMouseDown} />
    </div>
  )
}
