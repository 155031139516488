import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { debounce } from 'patronum'
import { TollRoad, TollRoadExportBankclient } from '~/shared/api'
import { openBlobFile } from '~/shared/lib/file/openBlobFile'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const Gate = createGate()
const domain = createDomain('entities.uploadTollRoads.list')

export const exportBankclientTXTFx = domain.createEffect<
  TollRoadExportBankclient,
  void
>({
  async handler(formValues) {
    const res = await TollRoad.exportBankclientTXT(formValues)
    openBlobFile(res, '_self')
  },
})

sample({
  clock: exportBankclientTXTFx.failData,
  fn() {
    return {
      message: 'Ошибка выгрузки в банк-клиент TXT',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const exportBankclientEXCELFx = domain.createEffect<
  TollRoadExportBankclient,
  void
>({
  async handler(formValues) {
    const res = await TollRoad.exportBankclientEXCEL(formValues)
    openBlobFile(res, '_self')
  },
})

sample({
  clock: exportBankclientEXCELFx.failData,
  fn() {
    return {
      message: 'Ошибка выгрузки в банк-клиент EXCEL',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Amount
export const fetchTollRoadAmount = domain.createEvent<string[] | undefined>()
export const fetchTollRoadAmountFx = domain.createEffect<
  string[] | undefined,
  number
>({
  async handler(tollRoadsIds) {
    if (!tollRoadsIds?.length) return 0
    return await TollRoad.getAmountSum(tollRoadsIds)
  },
})

export const $amount = domain
  .createStore<number | null>(0)
  .on(fetchTollRoadAmountFx.doneData, (_, amount) => amount)
  .on(Gate.close, () => 0)

export const debouncedFetchTollRoadsAmount = debounce({
  source: fetchTollRoadAmount,
  timeout: 400,
})

sample({
  clock: debouncedFetchTollRoadsAmount,
  target: fetchTollRoadAmountFx,
})

sample({
  clock: fetchTollRoadAmountFx.failData,
  fn() {
    return {
      message: 'Ошибка получения суммы платежа',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Get ids list
export const fetchTollRoadsIdsAllFx = domain.createEffect<
  Record<string, string>,
  Promise<string[]>
>({
  async handler(filters) {
    return await TollRoad.getIdList(filters)
  },
})
