import React from 'react'
import { ListEmployees } from '~/features/Employees/ListEmployees'
import { Container } from '~/shared/ui/Layout'

export const ListEmployeePage = () => {
  return (
    <Container>
      <ListEmployees />
    </Container>
  )
}
