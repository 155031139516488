import { createDomain, Domain } from 'effector'
import { useStoreMap } from 'effector-react'

type Options<Entity> = {
  domain?: Domain
  getEntityId: (entity: Entity) => UniqueId
  initialValue?: Record<UniqueId, Entity>
}

export function createCache<Entity>(options: Options<Entity>) {
  type CacheMap = Record<UniqueId, Entity>
  const { getEntityId, domain = createDomain(), initialValue = {} } = options

  const $cache = domain.createStore<{
    map: CacheMap
  }>({
    map: initialValue,
  })

  const updateCache = (
    source: { map: CacheMap },
    values: Entity[],
    forceUpdate = false,
  ) => {
    let modified = false
    values.forEach((value) => {
      const id = getEntityId(value)
      if (source.map[id] === value && !forceUpdate) {
        // skip
        return
      }

      source.map[id] = value
      modified = true
    })

    if (modified) return { map: source.map } // Recreate object to force update
    return source
  }

  const useCache = (id: UniqueId) =>
    useStoreMap($cache, (cache) => cache.map[id])

  return {
    $cache,
    updateCache,
    useCache,
  }
}
