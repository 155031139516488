import { useLocation, Navigate, Outlet } from 'react-router-dom'

import { viewerModel } from '~/entities/viewer'
import { history } from '~/shared/lib/history'

export function ProtectedPage() {
  const authenticated = viewerModel.useAuthenticated()
  const { pathname } = useLocation()
  const loginUrl = `/login?redirectUrl=${encodeURIComponent(pathname)}`

  return authenticated ? (
    <Outlet />
  ) : (
    <Navigate to={loginUrl} state={{ from: history.location }} />
  )
}
