import React from 'react'

import { ListUnloadPenalties } from '~/features/UnloadPenalties/ListUnloadPenalties'
import { Container, PageContainer } from '~/shared/ui/Layout'

export function ListUnloadPenaltiesPage() {
  return (
    <PageContainer title='Выгрузка в банк-клиент'>
      <Container>
        <ListUnloadPenalties />
      </Container>
    </PageContainer>
  )
}
