import React from 'react'
import { ListRole } from '~/features/Roles/ListRole'
import { Container } from '~/shared/ui/Layout'

export const ListRolePage = () => {
  return (
    <Container>
      <ListRole />
    </Container>
  )
}
