import { Grid } from '@mui/joy'
import React from 'react'
import {
  DatePickerInput,
  FormTitle,
  NumericInput,
  Switch,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type UnloadingDataProps = {
  loading: boolean
}

export function UnloadingData({ loading }: UnloadingDataProps) {
  return (
    <Container>
      <FormTitle>Выгрузка</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={3}>
          <DatePickerInput
            label='Дата ПП'
            name='bankclientLastExportDate'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <NumericInput
            label='Сумма ПП'
            name='bankclientLastExportAmount'
            numericProps={{
              suffix: ' руб',
            }}
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <Switch
            label='Платеж выгружен'
            name='bankclientExported'
            readOnly
            formControlProps={{ sx: { marginTop: '40px' } }}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={3}>
          <Switch
            label='Оплачено'
            name='paymentIsPaid'
            readOnly
            formControlProps={{ sx: { marginTop: '40px' } }}
            skeletonShow={loading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
