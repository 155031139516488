import { Snackbar, SnackbarCloseReason, Typography } from '@mui/joy'
import { useStore } from 'effector-react'
import { ReactNode } from 'react'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import {
  CloseIcon,
  ErrorIcon,
  SuccessIcon,
  WarningIcon,
} from '~/shared/ui/Icons'
import { $notifications, snackbarClosed } from './model'

type Props = { children: ReactNode }

export function Provider({ children }: Props) {
  const notifications = useStore($notifications)

  return (
    <>
      {notifications.map(({ message, variant, key }) => {
        const getIcon = () => {
          if (variant === 'success') return <SuccessIcon />
          if (variant === 'error') return <ErrorIcon />
          if (variant === 'warning') return <WarningIcon />
        }
        return (
          <Snackbar
            data-testid='snackbar-provider'
            key={key}
            open
            onClose={(_, closeReason: SnackbarCloseReason) => {
              if (closeReason === 'clickaway') return
              snackbarClosed(key)
            }}
            autoHideDuration={6000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            startDecorator={getIcon()}
            endDecorator={
              <IconButton onClick={() => snackbarClosed(key)}>
                <CloseIcon color={myPalette.gray['400']} />
              </IconButton>
            }
            sx={{ borderRadius: '12px', padding: '12px' }}
          >
            <Typography fontWeight={600}>{message}</Typography>
          </Snackbar>
        )
      })}
      <>{children}</>
    </>
  )
}
