import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { CompanyAttributes } from '~/shared/api'
import {
  FormProvider,
  formButtonsModel,
  FormTitle,
  TextInput,
  Switch,
  FormButtons,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { formSchema } from './model'

export type FormValues = CompanyAttributes

type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <FormTitle>Основная информация</FormTitle>
        <Grid container spacing={2.5}>
          <Grid xs={12}>
            <TextInput
              label='Наименование организации'
              name='name'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={3}>
            <TextInput
              label='ИНН'
              name='inn'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={3}>
            <TextInput
              label='ОГРН'
              name='ogrn'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={3}>
            <TextInput
              label='КПП'
              name='kpp'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={3}>
            <Switch
              label='Основной'
              name='payment'
              formControlProps={{ sx: { marginTop: '40px' } }}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>
      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
