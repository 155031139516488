import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import { Penalty } from '~/shared/api'

import { createCache } from '~/shared/lib/mapCacheFactory'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.penalty')

export const Gate = createGate<{ penaltyId: UniqueId }>()

export const $penaltyId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { penaltyId }) => penaltyId)

export const requestPenaltyFx = domain.createEffect<UniqueId, Penalty>({
  async handler(id) {
    return await fetchPenalty(id)
  },
})

const fetchPenalty = async (id: UniqueId) => {
  const response = await Penalty.find(id)
  return response.getData() as Penalty
}

sample({
  clock: $penaltyId,
  filter: isString,
  target: requestPenaltyFx,
})

const {
  $cache: $penaltyCache,
  useCache: usePenaltyCache,
  updateCache,
} = createCache<Penalty>({
  domain,
  getEntityId: (penalty) => penalty.getApiId() as UniqueId,
})
export { $penaltyCache, usePenaltyCache }

export const $penalty = combine($penaltyId, $penaltyCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

$penaltyCache.on(requestPenaltyFx.doneData, (cache, penalty) =>
  updateCache(cache, [penalty]),
)

export const $penaltyError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestPenaltyFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const usePenaltyError = (id: UniqueId) =>
  useStoreMap($penaltyError, (errors) => errors[id])
