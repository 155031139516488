import { Box, styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const DataGridWrapper = styled(Box)`
  position: relative;
  width: 100%;
  margin-bottom: 34px;

  .MuiDataGrid-root {
    border: none;
  }

  .MuiDataGrid-columnHeader:focus,
  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-columnHeaders {
    border: none;
  }

  .MuiDataGrid-columnHeader {
    padding: 12px 24px !important;
    font-size: 12px;
    font-weight: 500;
    color: ${myPalette.gray['600']};
    background: ${myPalette.gray['100']};
    border-radius: 30px;

    &.MuiDataGrid-columnHeaderCheckbox {
      padding: 0 !important;
    }

    .MuiDataGrid-columnSeparator {
      display: none;
    }
  }

  .MuiDataGrid-footerContainer {
    border: none;
  }

  .MuiDataGrid-row {
    border-bottom: 1px solid ${myPalette.gray['300']};

    &.Mui-selected {
      background-color: transparent !important;
    }

    &:hover {
      background-color: ${myPalette.gray['50']} !important;
    }
  }

  .MuiDataGrid-cell {
    padding: 12px 24px !important;
    border: none !important;
    font-size: 14px;
    color: ${myPalette.gray['600']};
  }
`
