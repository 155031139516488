import { Stack } from '@mui/joy'
import { Chip } from './styled'

type ChipListProps = {
  items: string[]
}

export function ColumnChipList({ items = [] }: ChipListProps) {
  return (
    <Stack direction='row' spacing={1}>
      {items.map((role) => (
        <Chip key={role}>{role}</Chip>
      ))}
    </Stack>
  )
}
