export function FilterFunnelIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.8215 4.72239C2.19121 4.01796 1.87607 3.66574 1.86418 3.3664C1.85386 3.10636 1.9656 2.85643 2.16628 2.69074C2.39728 2.5 2.86991 2.5 3.81515 2.5H16.1843C17.1295 2.5 17.6021 2.5 17.8331 2.69074C18.0338 2.85643 18.1456 3.10636 18.1352 3.3664C18.1233 3.66574 17.8082 4.01796 17.1779 4.72239L12.4227 10.037C12.2971 10.1774 12.2343 10.2477 12.1895 10.3276C12.1497 10.3984 12.1206 10.4747 12.1029 10.554C12.083 10.6435 12.083 10.7377 12.083 10.9261V15.382C12.083 15.5449 12.083 15.6264 12.0568 15.6969C12.0335 15.7591 11.9958 15.8149 11.9466 15.8596C11.8909 15.9102 11.8153 15.9404 11.664 16.001L8.83063 17.1343C8.52435 17.2568 8.3712 17.3181 8.24827 17.2925C8.14076 17.2702 8.04642 17.2063 7.98575 17.1148C7.91637 17.0101 7.91637 16.8452 7.91637 16.5153V10.9261C7.91637 10.7377 7.91637 10.6435 7.89647 10.554C7.87883 10.4747 7.84968 10.3984 7.80995 10.3276C7.76516 10.2477 7.70233 10.1774 7.57669 10.037L2.8215 4.72239Z'
        stroke='#3525E5'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
