import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useState } from 'react'
import { IconButton } from '~/shared/ui/Buttons'

type ExpandButton = {
  onClick: () => void
}

export function ExpandButton({ onClick }: ExpandButton) {
  const [isExpand, setIsExpand] = useState<boolean>(false)

  const handleOnClick = () => {
    setIsExpand((prevIsExpand) => !prevIsExpand)
    onClick()
  }

  return (
    <IconButton onClick={handleOnClick}>
      {isExpand ? (
        <ExpandLessIcon fontSize='small' />
      ) : (
        <ExpandMoreIcon fontSize='small' />
      )}
    </IconButton>
  )
}
