import { Stack } from '@mui/joy'
import { filters, ListPenalties } from 'src/features/Penalty/ListPenalties'
import { PenaltyExport } from '~/entities/Penalty'
import { usePermissions } from '~/entities/viewer'
import { formatDateTimeForApi } from '~/shared/lib/date'
import { pushFx } from '~/shared/lib/history'
import {
  getStringParams,
  useFiltersQueryParams,
} from '~/shared/ui/AsyncGrid/Filters'
import { Button } from '~/shared/ui/Buttons'
import { UploadIcon } from '~/shared/ui/Icons'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'

export function ListPenaltiesPage() {
  const { canExportPenaltyToBankClientExport } = usePermissions()

  const [filtersQueryParams] = useFiltersQueryParams(filters)
  const search = getStringParams(filtersQueryParams)

  const goToBankClient = () => {
    const frozenDateTime = formatDateTimeForApi(new Date())
    pushFx(`/penalties/unload/?frozenDateTime=${frozenDateTime}&${search}`)
  }

  return (
    <PageContainer title='Штрафы'>
      <Container
        isHeader
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <PageTitle>Штрафы</PageTitle>

        <Stack spacing={2} direction='row'>
          <Button
            show={canExportPenaltyToBankClientExport}
            onClick={goToBankClient}
            startDecorator={<UploadIcon />}
          >
            Выгрузить в банк клиент
          </Button>
          <PenaltyExport filters={filters} />
        </Stack>
      </Container>

      <Container>
        <ListPenalties />
      </Container>
    </PageContainer>
  )
}
