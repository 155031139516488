import { z } from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  name: z.string(),
  title: z.string().optional().nullable(),
  groupTitle: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type PermissionAttributes = z.infer<typeof schema>

export type OptionTitle = {
  id: UniqueId
  label: string
  title: string
  groupTitle: string
}

export class Permission extends ApiModel<typeof schema> {
  static jsonApiType = 'permissions'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }
  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  getOptionTitle(): OptionTitle {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
      title: this.getAttribute('title'),
      groupTitle: this.getAttribute('groupTitle'),
    }
  }
}
