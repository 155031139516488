import React from 'react'
import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { AddEmployeePage } from './Employees/AddEmployeePage'
import { EditEmployeePage } from './Employees/EditEmployeePage'
import { ListEmployeePage } from './Employees/ListEmployeePage'
import { AddRolePage } from './Roles/AddRolePage'
import { EditRolePage } from './Roles/EditRolePage'
import { ListRolePage } from './Roles/ListRolePage'

export const useConfigTabs = (): Tabs => {
  const { canUsersView, canRolesView } = usePermissions()

  return [
    ...canShowObject(canUsersView, {
      label: 'Сотрудники',
      path: '/employees',
      content: <ListEmployeePage />,
      children: [
        {
          path: '/new',
          content: <AddEmployeePage />,
          full: true,
        },
        {
          path: '/:employeeId',
          content: <EditEmployeePage />,
          full: true,
        },
      ],
    }),
    ...canShowObject(canRolesView, {
      label: 'Роли',
      path: '/roles',
      content: <ListRolePage />,
      children: [
        {
          path: '/new',
          content: <AddRolePage />,
          full: true,
        },
        {
          path: '/:roleId',
          content: <EditRolePage />,
          full: true,
        },
      ],
    }),
  ]
}
