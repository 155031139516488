import { useCallback } from 'react'
import { TollRoad } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { filters } from './filters'

import { GridRow } from './types'

export const ListTollRoads = () => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = TollRoad.limit(pageSize)

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((tollRoad: TollRoad) => ({
        ...tollRoad.getAttributes(),
        id: tollRoad?.getApiId() as UniqueId,
        eventTime: tollRoad.getEventDatetime(),
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='toll-roads-list'
      fetchRows={fetchRows}
      columns={columns}
      smallTitle='Проезды'
      filters={filters}
    />
  )
}
