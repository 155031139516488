import { Dropdown, MenuButton, Box, Badge, Stack } from '@mui/joy'
import { debounce } from 'lodash'
import isEqual from 'lodash/isEqual'
import { useEffect, useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useForm } from 'react-hook-form'
import { myPalette } from '~/shared/lib/theme'
import { IconButton, Button } from '~/shared/ui/Buttons'
import { FormProvider } from '~/shared/ui/Form'
import { RefreshIcon, SettingIcon } from '~/shared/ui/Icons'
import { useGridContext } from '../context'
import { DraggableList } from './DraggableList'
import {
  isDifferentColumns,
  mapColumnsToSettings,
  mapSettingsToColumns,
  mapSettingsToFormValues,
  reorder,
} from './helpers'
import { MenuItem, Menu } from './styled'
import { FormValues, ColumnSetting } from './type'
import { useStorageColumns } from './useStorageColumns'

export function SettingsColumns() {
  const { columns, defaultColumns, updateColumns, storageKeySettings } =
    useGridContext()

  const { setStorageSettings, getStorageSettings } =
    useStorageColumns(storageKeySettings)

  const defaultSettings = useMemo(
    () => mapColumnsToSettings(defaultColumns),
    [defaultColumns],
  )

  const getStorageDefaultSettings = useMemo(() => {
    const storageSettings = getStorageSettings()

    return !storageSettings?.length ||
      isDifferentColumns(defaultSettings, storageSettings)
      ? defaultSettings
      : storageSettings
  }, [defaultSettings, getStorageSettings])

  const [settings, setSettings] = useState<ColumnSetting[]>(
    getStorageDefaultSettings,
  )

  const form = useForm<FormValues>({
    defaultValues: mapSettingsToFormValues(settings),
  })

  const { watch, reset } = form

  const updateSettingsDebounce = debounce((formValues: FormValues) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) => ({
        ...setting,
        isActive: formValues[setting.field] ?? false,
      })),
    )
  }, 100)

  watch((formValues) => {
    updateSettingsDebounce(formValues)
  })

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return
    setSettings((prevSettings) =>
      reorder(prevSettings, source.index, destination.index),
    )
  }

  const handleResetSettings = () => {
    setSettings(defaultSettings)
    reset(mapSettingsToFormValues(defaultSettings))
  }

  const isChangedSettings = useMemo(
    () => isEqual(defaultSettings, settings),
    [defaultSettings, settings],
  )

  useEffect(() => {
    updateColumns(mapSettingsToColumns(settings, columns))
    setStorageSettings(settings)
    // eslint-disable-next-line
  }, [settings])

  return (
    <Box sx={{ position: 'relative' }}>
      <Dropdown>
        <MenuButton slots={{ root: 'div' }}>
          <Badge color='warning' invisible={isChangedSettings} variant='soft'>
            <IconButton variant='gray'>
              <SettingIcon />
            </IconButton>
          </Badge>
        </MenuButton>

        <Menu component={'div'} disablePortal>
          <Stack spacing={2}>
            <MenuItem component='div'>
              <Button
                fullWidth
                onClick={handleResetSettings}
                variant='gray'
                startDecorator={<RefreshIcon color={myPalette.brand['700']} />}
              >
                По умолчанию
              </Button>
            </MenuItem>
            <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
              <FormProvider form={form}>
                <DraggableList settings={settings} onDragEnd={onDragEnd} />
              </FormProvider>
            </Box>
          </Stack>
        </Menu>
      </Dropdown>
    </Box>
  )
}
