import React from 'react'
import { AddCompany } from '~/features/Companies/AddCompany'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { PageContainer, Container, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Организации', link: '/companies' },
  { title: 'Создание организации' },
]

export function AddCompanyPage() {
  return (
    <PageContainer title='Создание организации'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание организации</PageTitle>
      </Container>

      <AddCompany />
    </PageContainer>
  )
}
