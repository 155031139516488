import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { $permissions } from '~/entities/Permission/model'
import { roleModel } from '~/entities/Role'
import { roleFormModel } from '~/entities/Role'
import { Role } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ roleId: UniqueId }>()
export const domain = createDomain('features.roles.edit')

export const $roleId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { roleId }) => roleId)

export const $role = combine($roleId, roleModel.$rolesCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

sample({
  clock: $roleId,
  filter: isString,
  target: roleModel.requestFx,
})

export const roleUpdateFx = attach({
  effect: roleModel.saveFx,
  source: $roleId,
  mapParams: (values: roleFormModel.FormSuccessValues, id) => {
    const { permissionsIds, ...attr } = values
    const role = new Role(attr, id as string)

    if (Array.isArray(permissionsIds)) role.setPermissions(permissionsIds)

    return role
  },
})

export const formSubmitted =
  domain.createEvent<roleFormModel.FormSuccessValues>()
sample({
  clock: formSubmitted,
  target: roleUpdateFx,
})

sample({
  clock: roleUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: roleUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $editRoleModel = combine(
  $permissions,
  $role,
  (permissions, role): roleFormModel.FormValues => {
    const currentPermissions = role?.getPermissions() || []
    const mappedPermissions = (permissions || []).reduce(
      (objPermissions: roleFormModel.FormPermissionValues, permission) => {
        return {
          ...objPermissions,
          [permission.id]: currentPermissions.some(
            (currentPermission) =>
              currentPermission.getApiId() == permission.id,
          ),
        }
      },
      {},
    )
    return {
      name: role?.getName() || '',
      ...mappedPermissions,
    } as roleFormModel.FormValues
  },
)
