import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Unstable_Grid2 as Grid } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { Company, CompanyBankDetail } from '~/shared/api'
import { Button } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  FormProvider,
  NumericInput,
} from '~/shared/ui/Form'
import { FormValues } from './model'

type FormProps = {
  companyPending: boolean
  form: UseFormReturn<FormValues>
  onSuccessTXT: (formValues: FormValues) => void
  onSuccessEXCEL: (formValues: FormValues) => void
  isSubmittingTXT: boolean
  isSubmittingEXCEL: boolean
}

export function Form({
  companyPending,
  form,
  onSuccessTXT,
  onSuccessEXCEL,
  isSubmittingTXT,
  isSubmittingEXCEL,
}: FormProps) {
  const { watch, setValue, handleSubmit } = form
  const companyOption = watch('companyOption')

  watch((_, { name, type }) => {
    if (type !== 'change') return

    if (name === 'companyOption') {
      setValue('companyBankDetailOption', undefined)
    }
  })

  const handleOnSuccess = async (send: 'txt' | 'excel') => {
    await handleSubmit((formValues) => {
      if (send === 'txt') onSuccessTXT(formValues)
      if (send === 'excel') onSuccessEXCEL(formValues)
    })()
  }

  return (
    <FormProvider form={form}>
      <Grid container spacing={2.5}>
        <Grid xs={2.4}>
          <AsyncAutocompleteInput
            placeholder='Организация - плательщик'
            name='companyOption'
            fetchOptions={Company.fetchOptionsOfPayer}
            skeletonShow={companyPending}
          />
        </Grid>
        <Grid xs={2.4}>
          <AsyncAutocompleteInput
            placeholder='Расчетный счет'
            name='companyBankDetailOption'
            readOnly={!companyOption}
            queryKey={[companyOption?.companyId || '']}
            fetchOptions={(search) =>
              CompanyBankDetail.fetchOptions(search, companyOption?.companyId)
            }
            skeletonShow={companyPending}
          />
        </Grid>
        <Grid xs={2.4}>
          <NumericInput
            placeholder='Номер первого ПП'
            name='startNumber'
            numericProps={{
              thousandSeparator: '',
              decimalScale: 0,
            }}
          />
        </Grid>
        <Grid xs={2.4}>
          <Button
            onClick={() => handleOnSuccess('txt')}
            variant='gray'
            startDecorator={<FileUploadIcon />}
            loading={isSubmittingTXT}
            fullWidth
            sx={{ marginTop: '2px' }}
          >
            Выгрузить в TXT
          </Button>
        </Grid>
        <Grid xs={2.4}>
          <Button
            onClick={() => handleOnSuccess('excel')}
            variant='gray'
            startDecorator={<FileUploadIcon />}
            loading={isSubmittingEXCEL}
            fullWidth
            sx={{ marginTop: '2px' }}
          >
            Выгрузить в EXCEL
          </Button>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
