import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import * as z from 'zod'
import { AxiosErrorType, Company } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const formSchema = Company.schema

export type SchemaType = z.infer<typeof formSchema>
export type FormValues = SchemaType

export const domain = createDomain('entities.company')

export const Gate = createGate<{ companyId: UniqueId }>()

export const $companyId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { companyId }) => companyId)

const fetchCompany = async (id: UniqueId) => {
  const response = await Company.find(id)
  return response.getData() as Company
}

export const requestFx = domain.createEffect<UniqueId, Company>({
  handler: fetchCompany,
})

export const saveFx = domain.createEffect<Company, Company, AxiosErrorType>({
  async handler(company) {
    await company.save()
    return fetchCompany(company.getApiId() as UniqueId)
  },
})

sample({
  clock: $companyId,
  filter: isString,
  target: requestFx,
})
sample({
  clock: saveFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

const {
  $cache: $companyCache,
  useCache: useCompanyCache,
  updateCache,
} = createCache<Company>({
  domain,
  getEntityId: (company) => company.getApiId() as UniqueId,
})
export { $companyCache, useCompanyCache }

export const $company = combine($companyId, $companyCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

$companyCache
  .on(requestFx.doneData, (cache, company) => updateCache(cache, [company]))
  .on(saveFx.doneData, (cache, company) => updateCache(cache, [company], true))

export const $companyError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useCompanyError = (id: UniqueId) =>
  useStoreMap($companyError, (errors) => errors[id])
