import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography, Skeleton, Grid } from '@mui/joy'
import { GridRowId } from '@mui/x-data-grid/models/gridRows'
import { useStore } from 'effector-react'
import { omit } from 'lodash'
import {
  forwardRef,
  JSXElementConstructor,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  Amount,
  AmountTitle,
  AmountWrapper,
} from '~/entities/UnloadTollRoads/ui/List/styled'
import { CompanyFetchOption, Penalty } from '~/shared/api'
import { priceFormat } from '~/shared/lib/utils'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Button } from '~/shared/ui/Buttons'
import { Checkbox } from '~/shared/ui/Form/ui/styled'
import { columns } from './columns'
import { Filters } from './Filters'
import { Form, uploadPenaltiesFormModel } from './Form'
import { mapFilterQueryToExternalFilter } from './mappers'
import {
  $amount,
  debouncedFetchPenaltiesAmount,
  fetchPenaltiesAmountFx,
  fetPenaltiesIdsAllFx,
} from './model'
import { GridRow } from './types'
import { useFilters } from './useFilters'

type ListProps = {
  companyPending: boolean
  companyDefaultValue: CompanyFetchOption | null
  defaultFilters: Record<string, string>
  onSuccessTXT: (
    formValues: uploadPenaltiesFormModel.FormValues,
    selectedRowIds?: string[],
  ) => void
  onSuccessEXCEL: (
    formValues: uploadPenaltiesFormModel.FormValues,
    selectedRowIds?: string[],
  ) => void
  isSubmittingTXT: boolean
  isSubmittingEXCEL: boolean
  frozenDateTime: string
}

const CheckboxAdapter = forwardRef<
  HTMLElement,
  JSXElementConstructor<{
    checked: boolean
  }>
>(function Wrapper(params, ref) {
  return (
    <Checkbox
      data-testid='grid-checkbox'
      {...omit(params, ['inputProps', 'touchRippleRef'])}
      ref={ref}
    />
  )
})

const breadcrumbs: BreadcrumbsList = [
  { title: 'Штрафы', link: '/penalties' },
  { title: 'Выгрузка в банк-клиент' },
]

export const List = ({
  companyPending,
  companyDefaultValue,
  defaultFilters,
  onSuccessTXT,
  onSuccessEXCEL,
  isSubmittingTXT,
  isSubmittingEXCEL,
  frozenDateTime,
}: ListProps) => {
  const amountPending = useStore(fetchPenaltiesAmountFx.pending)
  const selectAllPending = useStore(fetPenaltiesIdsAllFx.pending)
  const amount = useStore($amount) || 0
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>()

  const handleSelectedRowIdsSet = (ids: GridRowId[]) => setSelectedRowIds(ids)
  const handleSelectedRowIdsReset = () => setSelectedRowIds([])

  const { filterQuery } = useFilters()

  const form = useForm<uploadPenaltiesFormModel.FormValues>({
    resolver: zodResolver(uploadPenaltiesFormModel.formSchema),
    defaultValues: {
      companyOption: companyDefaultValue || undefined,
    },
  })
  useEffect(() => {
    if (companyDefaultValue)
      form.setValue('companyOption', companyDefaultValue || undefined)
    // eslint-disable-next-line
  }, [companyDefaultValue, companyPending])

  const companyOption = form.watch('companyOption')
  const externalFilters = useMemo(
    () => mapFilterQueryToExternalFilter(filterQuery, companyOption),
    [companyOption, filterQuery],
  )

  const externalFiltersKey = useMemo(
    () => JSON.stringify(externalFilters),
    [externalFilters],
  )

  useEffect(() => {
    handleSelectedRowIdsReset()
  }, [externalFiltersKey])

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = Penalty.limit(pageSize).where(
        'createdAtLte',
        frozenDateTime,
      )

      Object.entries(defaultFilters).forEach(([key, value]) => {
        if (!value) return
        builder = builder.where(key, value)
      })

      filters?.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((penalty: Penalty) => ({
        ...penalty.getAttributes(),
        id: penalty?.getApiId() as UniqueId,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [defaultFilters, frozenDateTime],
  )

  const handleOnSuccessTXT = (
    formValues: uploadPenaltiesFormModel.FormValues,
  ) => {
    onSuccessTXT(formValues, selectedRowIds?.map(String))
  }
  const handleOnSuccessEXCEL = (
    formValues: uploadPenaltiesFormModel.FormValues,
  ) => {
    onSuccessEXCEL(formValues, selectedRowIds?.map(String))
  }

  useEffect(() => {
    debouncedFetchPenaltiesAmount(selectedRowIds?.map(String))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds?.length])

  const handlePenaltiesSelectAll = async () => {
    const params = [
      ...Object.entries(defaultFilters),
      ...externalFilters.map(({ key, value }) => [key, value]),
    ].reduce((values: Record<string, string>, [key, value]) => {
      if (!value) return values
      return {
        ...values,
        [`filter[${key}]`]: typeof value === 'boolean' ? '1' : value,
      }
    }, {})

    const penaltyIds = await fetPenaltiesIdsAllFx(params)
    setSelectedRowIds(penaltyIds)
  }

  return (
    <AsyncGrid<GridRow>
      title='Выгрузка в банк-клиент'
      breadcrumbs={breadcrumbs}
      gridKey='unload-penalties-list'
      fetchRows={fetchRows}
      columns={columns}
      checkboxSelection
      keepNonExistentRowsSelected
      selectionModel={selectedRowIds}
      onSelectionModelChange={handleSelectedRowIdsSet}
      externalFilter={externalFilters}
      headerExtra={
        <>
          <Typography>Выбрано: {selectedRowIds?.length || 0} строк</Typography>
          <AmountWrapper>
            <AmountTitle>Сумма платежа:</AmountTitle>
            <Amount data-testid='payment-amount'>
              <Skeleton loading={amountPending} sx={{ borderRadius: '8px' }} />
              {priceFormat(amount)} руб.
            </Amount>
          </AmountWrapper>
        </>
      }
      gridExtraTop={
        <>
          <Box sx={{ mb: '12px' }}>
            <Form
              form={form}
              companyPending={companyPending}
              onSuccessTXT={handleOnSuccessTXT}
              onSuccessEXCEL={handleOnSuccessEXCEL}
              isSubmittingTXT={isSubmittingTXT}
              isSubmittingEXCEL={isSubmittingEXCEL}
            />
          </Box>

          <Box sx={{ mb: '22px' }}>
            <Filters />
          </Box>
          <Grid container spacing={2.5} sx={{ mb: '12px' }}>
            <Grid xs={2.4}>
              <Button
                loading={selectAllPending}
                onClick={handlePenaltiesSelectAll}
                fullWidth
                variant='gray'
              >
                Выбрать все штрафы
              </Button>
            </Grid>
            <Grid xs={2.4}>
              <Button
                fullWidth
                variant='gray'
                onClick={handleSelectedRowIdsReset}
              >
                Отменить выбор
              </Button>
            </Grid>
          </Grid>
        </>
      }
      components={{
        BaseCheckbox: CheckboxAdapter,
      }}
    />
  )
}
