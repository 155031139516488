import { Draggable } from '@hello-pangea/dnd'
import React from 'react'
import { myPalette } from '~/shared/lib/theme'
import { Checkbox } from '~/shared/ui/Form'
import { MenuIcon } from '~/shared/ui/Icons'
import { DraggableIcon, DraggableItemWrapper } from './styled'
import { ColumnSetting } from './type'

export type DraggableItemProps = {
  setting: ColumnSetting
  index: number
  disabled: boolean
}

export function DraggableItem({
  setting,
  index,
  disabled,
}: DraggableItemProps) {
  return (
    <Draggable draggableId={setting.field} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, snapshot) => (
        <DraggableItemWrapper
          {...draggableProps}
          sx={{
            ...draggableProps.style,
            ...(snapshot.isDragging
              ? { background: myPalette.gray['50'] }
              : {}),
          }}
          ref={innerRef}
        >
          <DraggableIcon {...dragHandleProps}>
            <MenuIcon />
          </DraggableIcon>

          <Checkbox
            disabled={disabled}
            name={setting.field}
            label={setting.headerName}
          />
        </DraggableItemWrapper>
      )}
    </Draggable>
  )
}
