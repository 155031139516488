import { Box, styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Wrapper = styled(Box)`
  & {
    border-color: ${myPalette.gray['100']};
    border-radius: 16px;
    padding: 6px;
  }

  .MuiAutocomplete-option {
    border-radius: 12px;
    padding: 0 8px;
    margin: 2px 0;
    font-size: 16px;

    &:hover:not([aria-selected='true']) {
      background-color: ${myPalette.gray['50']} !important;
    }
  }
`
