import {
  Box,
  styled,
  Typography,
  Select as JoySelect,
  Option as JoyOption,
  selectClasses,
} from '@mui/joy'
import { Pagination as MuiPagination } from '@mui/material'
import { myPalette } from '~/shared/lib/theme'

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Pagination = styled(MuiPagination)`
  .MuiPaginationItem-root {
    border: none;
    height: 44px;
    width: 44px;
    border-radius: 30px;
  }

  .MuiPaginationItem-root {
    &.Mui-selected,
    &:hover {
      background-color: ${myPalette.brand['100']};
    }
  }

  .MuiPaginationItem-ellipsis {
    vertical-align: bottom;
    display: inline;

    &:hover {
      background-color: transparent;
    }
  }
`

export const RowsPerPage = styled(Box)`
  display: flex;
  align-items: center;
`

export const RowsPerPageText = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`
export const Select = styled(JoySelect)`
  margin: 0 12px;
  font-size: 14px;
  font-weight: 600;
  width: 80px;
  border-radius: 30px;
  height: 44px;

  & .${selectClasses.indicator} {
    transition: 0.2s;

    &.${selectClasses.expanded} {
      transform: rotate(-180deg);
    }
  }
` as typeof JoySelect

export const Option = styled(JoyOption)`
  border-radius: 30px;
`
