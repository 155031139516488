import {
  BooleanParam,
  NumberParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { UnloadPenaltiesFilterEnum } from '~/entities/UnloadPenalties'
import { UnloadTollRoadsFilterEnum } from '~/entities/UnloadTollRoads'

export const useFilters = () => {
  const [filterQuery, setFilterQuery] = useQueryParams({
    [UnloadTollRoadsFilterEnum.IS_FILTERED_ORGANIZATION]: withDefault(
      BooleanParam,
      true,
    ),
    [UnloadPenaltiesFilterEnum.PAYMENT_AMOUNT]: withDefault(
      NumberParam,
      undefined,
    ),
  })
  return {
    filterQuery,
    setFilterQuery,
  }
}
