import { useStore, useStoreMap } from 'effector-react'
import { tollRoadModel, Form } from '~/entities/TollRoad'
import { formatDateTimeForUI } from '~/shared/lib/date'

export function ViewTollRoad() {
  const isLoading = useStore(tollRoadModel.requestTollRoadFx.pending)

  const defaultValues = useStoreMap(tollRoadModel.$tollRoad, (tollRoad) => {
    const createdAt = tollRoad?.getCreatedAt()
    return {
      ...tollRoad?.getAttributes(),
      carBrandModel: tollRoad?.getBrandModel(),
      createdAt: createdAt ? formatDateTimeForUI(createdAt) : '',
    }
  })

  return <Form isLoading={isLoading} defaultValues={defaultValues} />
}
