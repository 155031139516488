import {
  FormControl,
  FormLabel,
  Typography,
  CheckboxProps as TypeCheckboxProps,
  FormControlProps,
} from '@mui/joy'
import { Controller } from 'react-hook-form'
import { Checkbox as StyledCheckbox } from '../ui/styled'

type CheckboxProps = {
  name: string
  label: string
  formControlProps?: FormControlProps
} & TypeCheckboxProps

export function Checkbox({
  name,
  label,
  formControlProps,
  ...props
}: CheckboxProps) {
  return (
    <Controller
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <FormControl
          orientation='horizontal'
          {...formControlProps}
          sx={{ alignItems: 'center', ...formControlProps?.sx }}
        >
          <StyledCheckbox
            {...props}
            checked={!!value}
            onChange={() => !props.readOnly && onChange(!value)}
            sx={{
              ...(props.readOnly
                ? {
                    input: {
                      cursor: 'unset !important',
                    },
                  }
                : {}),
            }}
          />
          <FormLabel sx={{ marginLeft: '8px' }}>
            <Typography fontSize='14px'>{label}</Typography>
          </FormLabel>
        </FormControl>
      )}
    />
  )
}
