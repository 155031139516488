import { createDomain, sample, attach } from 'effector'
import { FormValues, companyModel } from '~/entities/Company'
import { Company } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const domain = createDomain('features.companies.edit')

sample({
  clock: companyModel.$companyId,
  filter: isString,
  target: companyModel.requestFx,
})

export const companyUpdateFx = attach({
  effect: companyModel.saveFx,
  source: companyModel.$companyId,
  mapParams: (values: FormValues, id) => {
    const { ...attr } = values
    return new Company(attr, id as string)
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: companyUpdateFx,
})

sample({
  clock: companyUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: companyUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка при сохранении данных организации',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
