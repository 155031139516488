import { uniq } from 'lodash'
import { useMemo } from 'react'
import { usePermissions } from '~/entities/viewer'

export const usePermissionsPage = () => {
  const {
    canUsersView,
    canUsersUpdate,
    canRolesUpdate,
    canRolesView,
    canCompanyUpdate,
    canCompaniesView,
    canPenaltyView,
    canExportPenaltyToBankClientExport,
    canSettingsView,
    canTollRoadView,
    canExportTollRoadToBankClientExport,
  } = usePermissions()

  const notPermissions = useMemo(() => {
    let data: string[] = []

    if (!canSettingsView) data = [...data, '/settings/*']

    if (!canUsersView)
      data = [...data, '/settings/employees', '/settings/employees/*']
    if (!canUsersUpdate) data = [...data, '/settings/employees/new']

    if (!canRolesView) data = [...data, '/settings/roles', '/settings/roles/*']
    if (!canRolesUpdate) data = [...data, '/settings/roles/new']

    if (!canCompaniesView)
      data = [...data, '/companies', '/companies/:companyId', '/companies/new']
    if (!canCompanyUpdate) data = [...data, '/companies/new']

    if (!canPenaltyView)
      data = [
        ...data,
        '/penalties',
        '/penalties/:companyId',
        '/penalties/unload',
      ]
    if (!canExportPenaltyToBankClientExport)
      data = [...data, '/penalties/unload']

    if (!canTollRoadView)
      data = [
        ...data,
        '/toll-roads',
        '/toll-roads/:tollRoadId',
        '/toll-roads/unload',
      ]
    if (!canExportTollRoadToBankClientExport)
      data = [...data, '/toll-roads/unload']

    return uniq<string>(data)
  }, [
    canSettingsView,
    canUsersView,
    canUsersUpdate,
    canRolesView,
    canRolesUpdate,
    canCompaniesView,
    canCompanyUpdate,
    canPenaltyView,
    canExportPenaltyToBankClientExport,
    canTollRoadView,
    canExportTollRoadToBankClientExport,
  ])

  return { notPermissions }
}
