import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useGate, useStore } from 'effector-react'
import { omit } from 'lodash'
import React, { useMemo } from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { filters } from '~/features/Penalty/ListPenalties'
import {
  List,
  UnloadPenaltiesFilterEnum,
  unloadPenaltiesModel,
  uploadPenaltiesFormModel,
} from '~/entities/UnloadPenalties'
import { PenaltyExportBankclient } from '~/shared/api'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { useFiltersQueryParams } from '~/shared/ui/AsyncGrid/Filters'
import { $companyOption, fetchCompanyFx, Gate } from './model'

const mapFormValues = (
  formValues: uploadPenaltiesFormModel.FormValues,
  selectedPenaltiesIds: string[],
): PenaltyExportBankclient => ({
  startNumber: String(formValues.startNumber),
  companyBankDetailId: formValues?.companyBankDetailOption as string,
  penaltiesIds: selectedPenaltiesIds,
})

export const ListUnloadPenalties = () => {
  const queryClient = useQueryClient()

  const [defaultFilters] = useFiltersQueryParams(filters)
  const [frozenDateTime] = useQueryParam(
    'frozenDateTime',
    withDefault(StringParam, ''),
  )

  useGate(Gate, { inn: defaultFilters?.paymentOrgInn?.id })

  const handleListUpdate = () => {
    queryClient.invalidateQueries({
      queryKey: ['unload-penalties-list'],
    })
  }

  const mappedDefaultFilters = useMemo(() => {
    return Object.entries(defaultFilters).reduce(
      (values: Record<string, string>, [key, value]) => {
        if (!value) return values
        return {
          ...values,
          [key]: String(value?.id ? value.id : value),
        }
      },
      {},
    )
  }, [defaultFilters])

  const companyPending = useStore(fetchCompanyFx.pending)
  const companyOption = useStore($companyOption)
  const isSubmittingTXT = useStore(
    unloadPenaltiesModel.exportBankclientTXTFx.pending,
  )
  const isSubmittingEXCEL = useStore(
    unloadPenaltiesModel.exportBankclientEXCELFx.pending,
  )

  const handleOnSuccessTXT = async (
    formValues: uploadPenaltiesFormModel.FormValues,
    selectedPenaltiesIds?: string[],
  ) => {
    if (!selectedPenaltiesIds?.length) {
      snackbarEnqueued({
        message: 'Для выгрузки TXT выберите штрафы',
        variant: 'warning',
      })
      return
    }

    await unloadPenaltiesModel.exportBankclientTXTFx(
      mapFormValues(formValues, selectedPenaltiesIds),
    )
    handleListUpdate()
  }

  const handleOnSuccessEXCEL = async (
    formValues: uploadPenaltiesFormModel.FormValues,
    selectedPenaltiesIds?: string[],
  ) => {
    if (!selectedPenaltiesIds?.length) {
      snackbarEnqueued({
        message: 'Для выгрузки EXCEL выберите штрафы',
        variant: 'warning',
      })
      return
    }
    await unloadPenaltiesModel.exportBankclientEXCELFx(
      mapFormValues(formValues, selectedPenaltiesIds),
    )
    handleListUpdate()
  }

  return (
    <Box sx={{ width: '100%' }}>
      <List
        companyPending={companyPending}
        companyDefaultValue={companyOption}
        defaultFilters={omit(
          mappedDefaultFilters,
          UnloadPenaltiesFilterEnum.PAYMENT_ORG_INN,
        )}
        onSuccessTXT={handleOnSuccessTXT}
        onSuccessEXCEL={handleOnSuccessEXCEL}
        isSubmittingTXT={isSubmittingTXT}
        isSubmittingEXCEL={isSubmittingEXCEL}
        frozenDateTime={frozenDateTime}
      />
    </Box>
  )
}
