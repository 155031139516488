import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import { Employee } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = Employee.schema.pick({
  name: true,
  email: true,
  rolesIds: true,
})
export type SchemaType = z.infer<typeof formSchema>
export type FormValues = Omit<SchemaType, 'rolesIds'> & {
  rolesIds?: UniqueId[] | Option[]
}

export const domain = createDomain('entities.employee')

export const requestFx = domain.createEffect<UniqueId, Employee>({
  handler: fetchEmployeeWithRelations,
})

export const saveFx = domain.createEffect<Employee, Employee>({
  async handler(employee) {
    await employee.save()
    return fetchEmployeeWithRelations(employee.getApiId() as UniqueId)
  },
})

const {
  $cache: $employeesCache,
  useCache: useEmployeeCache,
  updateCache,
} = createCache<Employee>({
  domain,
  getEntityId: (employee) => employee.getApiId() as UniqueId,
})
export { $employeesCache, useEmployeeCache }

$employeesCache
  .on(requestFx.doneData, (cache, employee) => updateCache(cache, [employee]))
  .on(saveFx.doneData, (cache, employee) =>
    updateCache(cache, [employee], true),
  )

export const $employeesError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useEmployeeError = (id: UniqueId) =>
  useStoreMap($employeesError, (errors) => errors[id])

async function fetchEmployeeWithRelations(id: UniqueId) {
  const response = await Employee.with('roles').find(id)
  return response.getData() as Employee
}
