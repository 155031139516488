import { omit } from 'lodash'
import { OptionTitle } from '~/shared/api'
import { FormSuccessValues, FormValues } from './model'

export const mapSuccessData = (formData: FormValues): FormSuccessValues => ({
  name: formData.name,
  permissionsIds: Object.entries(omit(formData, 'name'))
    .filter(([, value]) => value)
    .map(([permissionId]) => permissionId),
})

export const mapPermissionsToGroupPermissions = (
  permissions: OptionTitle[],
) => {
  return Object.entries(
    (permissions || []).reduce(
      (grouped: Record<string, OptionTitle[]>, permission) => {
        if (!permission?.groupTitle) return grouped

        const group = grouped[permission.groupTitle]

        return {
          ...grouped,
          [permission.groupTitle]: [
            ...(group?.length ? group : []),
            permission,
          ],
        }
      },
      {},
    ),
  )
}
