import { Stack, LinearProgress } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { useState } from 'react'
import { Form, companyModel } from '~/entities/Company'
import { usePermissions } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { FormTitle } from '~/shared/ui/Form'
import { PlusIcon } from '~/shared/ui/Icons'
import { Container } from '~/shared/ui/Layout'
import { AddCompanyBankDetail } from './AddCompanyBankDetail'
import { EditListCompanyBankDetail } from './EditListCompanyBankDetail'
import { editListCompanyBandDetailModel } from './EditListCompanyBankDetail'
import { formSubmitted, companyUpdateFx } from './model'

export function DetailsCompany() {
  const {
    canCompanyBankDetailView,
    canCompanyUpdate,
    canCompanyBankDetailUpdate,
  } = usePermissions()

  const [isAddBankDetail, toggleIsAddBankDetail] = useState<boolean>(false)

  const handleAddBankDetailHidden = () => {
    toggleIsAddBankDetail(false)
  }
  const handleAddBankDetailVisible = () => {
    toggleIsAddBankDetail(true)
  }

  const isBankDetailLoading = useStore(
    editListCompanyBandDetailModel.fetchCompanyBankDetailsFx.pending,
  )
  const isLoading = useStore(companyModel.requestFx.pending)
  const isSaving = useStore(companyUpdateFx.pending)

  const defaultValues = useStoreMap(companyModel.$company, (company) =>
    company?.getAttributes(),
  )

  return (
    <>
      <Form
        isLoading={isLoading}
        defaultValues={defaultValues}
        onSuccess={formSubmitted}
        isSubmitting={isSaving}
        canEdit={canCompanyUpdate}
      />
      {canCompanyBankDetailView && (
        <>
          <Container>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='flex-start'
            >
              <FormTitle>Банковские реквизиты</FormTitle>
              <Button
                startDecorator={<PlusIcon />}
                show={canCompanyBankDetailUpdate}
                onClick={handleAddBankDetailVisible}
              >
                Добавить
              </Button>
            </Stack>

            {isBankDetailLoading && <LinearProgress size='lg' />}
            {!isBankDetailLoading && (
              <>
                <AddCompanyBankDetail
                  isVisible={isAddBankDetail}
                  hiddenFn={handleAddBankDetailHidden}
                />

                <EditListCompanyBankDetail />
              </>
            )}
          </Container>
        </>
      )}
    </>
  )
}
