import * as z from 'zod'
import { Role } from '~/shared/api'

export const formSchema = Role.schema.pick({
  name: true,
})

export type FormPermissionsIds = string[]

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormPermissionValues = Record<string, boolean>
export type FormValues = TypeFormSchema & FormPermissionValues
export type FormSuccessValues = TypeFormSchema & {
  permissionsIds: FormPermissionsIds
}
