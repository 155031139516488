import { AxiosResponse } from 'axios'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import * as z from 'zod'

import { UserOptionsMe } from '~/shared/api/core/store'
import {
  dateSchema,
  phoneScheme,
  uuidOptionsSchema,
} from '~/shared/lib/schemas'

import { ApiModel } from './core'

const attributeSchema = z.object({
  email: z.string().email('Неверный адрес электронной почты'),
  password: z
    .string()
    .min(5, { message: 'Пароль должен содержать не менее 5 символов' }),
  name: z.string(),
  phone: phoneScheme,
  telegramUserId: z.number().nullable(),
})

const relationsSchema = z.object({
  regionsIds: uuidOptionsSchema,
  rolesIds: uuidOptionsSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type UserAttributes = z.infer<typeof schema>

export class User extends ApiModel<typeof schema> {
  static jsonApiType = 'users'

  static schema = schema

  static async login(credentials: { email: Email; password: string }) {
    const url = `${this.getJsonApiUrl()}/login`
    const client = this.httpClient.getImplementingClient()
    return client
      .post<
        typeof credentials,
        AxiosResponse<{ token: string; id: UniqueId; email: string }>
      >(url, credentials, {
        skipAuthRefresh: true,
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      } as AxiosAuthRefreshRequestConfig)
      .then(({ data }) => data)
  }

  static async me() {
    const url = `${this.getJsonApiUrl()}/me`
    const client = User.httpClient.getImplementingClient()
    return client.get<UserOptionsMe>(url).then(({ data }) => data)
  }

  static async logout() {
    const url = `${this.getJsonApiUrl()}/logout`
    return User.effectiveHttpClient.post(url)
  }

  getEmail(): string {
    return this.getAttribute('email')
  }

  getName(): string {
    return this.getAttribute('name')
  }

  getPhone(): string | undefined {
    return this.getAttribute('phone')
  }
}
