import { Box, Stack } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from '~/widgets/AuditsModal'
import { DetailsCompany } from 'src/features/Companies/DetailsCompany'
import { companyModel } from '~/entities/Company'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export function ViewCompanyPage() {
  const { canAuditsView } = usePermissions()
  const params = useParams<{ companyId: string }>()
  const companyId = params.companyId as string

  useGate(companyModel.Gate, { companyId })

  const company = useStore(companyModel.$company)
  const isLoading = useStore(companyModel.requestFx.pending)

  const title = company?.getName() || ''

  const error = companyModel.useCompanyError(companyId)

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [{ title: 'Организации', link: '/companies' }, { title }],
    [title],
  )

  if (!companyId || error) {
    return <NotFound message='Не удалось загрузить организацию' />
  }

  return (
    <PageContainer title='Карточка сотрудника'>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <PageTitle loading={isLoading}>{title}</PageTitle>
          </Box>
          {canAuditsView && <AuditsModal auditableId={companyId} />}
        </Stack>
      </Container>

      <DetailsCompany />
    </PageContainer>
  )
}
