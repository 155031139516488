import { Button, IconButton } from '~/shared/ui/Buttons'
import { HistoryEduIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { List } from './ui/List'

type HistoryChangesProps = {
  auditableId: UniqueId
}

export function AuditsModal({ auditableId }: HistoryChangesProps) {
  const { isModalShow, openModal, closeModal } = useModal()

  return (
    <>
      <IconButton
        data-testid='audit-button'
        onClick={openModal}
        tooltipProps={{ title: 'История изменений' }}
      >
        <HistoryEduIcon />
      </IconButton>

      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        dialogProps={{
          sx: {
            minWidth: '1000px',
            maxWidth: '1000px',
          },
        }}
        title='История изменений'
      >
        <List auditableId={auditableId} />
        <br />
        <Button variant='gray' onClick={closeModal} fullWidth>
          Закрыть
        </Button>
      </Modal>
    </>
  )
}
